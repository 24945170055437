import {
  ChangePasswordBreadCrumb,
  MfAuthBreadCrumb,
  SettingsBreadCrumb,
} from "@/module/core/breadcrumbs";
import { RouteInterface } from "@kinherit/framework/core/route-config";

export const SettingsIndexRoute = "SettingsIndex";
export const SettingsChangePasswordRoute = "SettingsChangePassword";
export const SettingsMfAuthMethodsRoute = "SettingsMfAuthMethods";
export const SettingsCreateMfAuthMethodsRoute = "SettingsCreateMfAuthMethods";

export const Routes: Array<RouteInterface> = [
  {
    name: SettingsIndexRoute,
    path: "/settings",
    component: () => import("./Index.vue"),
    meta: {
      breadcrumbs: SettingsBreadCrumb,
    },
    children: [
      {
        name: SettingsChangePasswordRoute,
        path: "change-password",
        component: () => import("./ChangePassword.vue"),
        meta: {
          breadcrumbs: ChangePasswordBreadCrumb,
        },
      },
      {
        name: SettingsMfAuthMethodsRoute,
        path: "authentication-methods",
        component: () => import("./MfAuthMethods.vue"),
        meta: {
          breadcrumbs: MfAuthBreadCrumb,
        },
      },
      {
        name: SettingsCreateMfAuthMethodsRoute,
        path: "authentication-methods/create",
        component: () => import("./CreateMfAuthMethod.vue"),
        meta: {
          breadcrumbs: MfAuthBreadCrumb,
        },
      },
    ],
  },
];
