import { createRouter, createWebHistory } from "vue-router";
import { Module } from "./module.config";

const Router = createRouter({
  history: createWebHistory(),
  routes: Module.routes(),
  scrollBehavior: (to, from, savedPosition) => {
    if (to.name === from.name) {
      return;
    }

    return savedPosition ?? { left: 0, top: 0 };
  },
});

Router.afterEach((to, from) => {
  if (to.name === from.name) {
    return;
  }

  window.Kernel.ComponentOptions.App.callRefresh();
});

Router.beforeEach((to, from, next) => {
  window.Kernel.Logger.log(
    "Global",
    "info",
    `Navigating to ${to.name?.toString()}`,
  );

  next();
});

export { Router };
