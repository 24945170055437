// import { AuthService } from "@/service/auth.service";
// import { getPagination } from "@kinherit/framework/action-bus/helper";
// import { AccountReferral, Api, IOrder, Order, OrderItem } from "@kinherit/sdk";

// export const ReadAccountReferralOrdersHandler = async (message: {
//   accountReferral: string | AccountReferral;
//   sort: {
//     by: keyof IOrder;
//     direction: "desc" | "asc";
//   };
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }) => {
//   const company = AuthService.activeIntroducerCompany;

//   if (!company) {
//     throw new Error("No active company");
//   }

//   const request = Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/account-referral/{accountReferral}/order",
//     message,
//   )
//     .method("get")
//     .sort(message.sort)
//     .paginate(message.pagination);

//   const response = await request.result();

//   return {
//     orders: Order.$inflate(response.order, message.sort, response.$rootIds),
//     orderItems: OrderItem.$inflate(response.orderItem),
//     pagination: getPagination(response),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferral, Order } from "@kinherit/sdk";

export const ReadAccountReferralOrdersHandler = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/account-referral/{accountReferral}/order",
  method: "get",
  rootModel: Order,
  parse: (message: { accountReferral: string | AccountReferral }) => ({
    params: {
      accountReferral: message.accountReferral,
    },
  }),
});
