import { Api } from "@/service/api.service";
import { Session } from "../../model/session.model";

interface RequestMultiFactorAuthMessage {
  session: Session;
  methodId: string;
}

export interface RequestMultiFactorAuthResult {
  session: Session;
  multiFactorSessionId: string;
  code?: number;
}

export const RequestMultiFactorAuthHandler = async (
  message: RequestMultiFactorAuthMessage,
): Promise<RequestMultiFactorAuthResult> => {
  const activeSession = message.session;

  const response = await Api.resource("auth", "/v2/auth/multi-factor/request")
    .method("put")
    .body({
      methodId: message.methodId,
    })
    .result();

  return {
    session: activeSession,
    multiFactorSessionId: response.session.id,
    code: response.session.code,
  };
};
