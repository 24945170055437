import {
  PartnerPortalAccountReferralDetailsBreadCrumb,
  PartnerPortalAccountReferralFormBreadCrumb,
  PartnerPortalAccountReferralMasterListBreadCrumb,
} from "@/module/clients/breadcrumbs";

import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const PartnerPortalClientIndexRoute = "PartnerPortalClientIndex";

export const PartnerPortalAccountReferralMasterListRoute =
  "PartnerPortalAccountReferralMasterList";

export const PartnerPortalAccountReferralDetailsRoute =
  "PartnerPortalAccountReferralDetails";
const PartnerPortalAccountReferralDetailsUri = "client/:accountReferral";

export const PartnerPortalAccountReferralFormRoute =
  "PartnerPortalAccountReferralForm";
const PartnerPortalAccountReferralFormUri = "client/new";

export type PartnerPortalAccountReferralDetailsParams = RouteParamObject<
  typeof PartnerPortalAccountReferralDetailsUri
>;

export const Routes: Array<RouteInterface> = [
  {
    name: PartnerPortalClientIndexRoute,
    path: "/clients",
    component: () => import("./Index.vue"),
    redirect: { name: PartnerPortalAccountReferralMasterListRoute },
    children: [
      {
        name: PartnerPortalAccountReferralMasterListRoute,
        path: "/clients",
        component: () => import("./AccountReferralMasterList.vue"),
        meta: {
          breadcrumbs: PartnerPortalAccountReferralMasterListBreadCrumb,
          title: `Clients`,
        },
      },
      {
        name: PartnerPortalAccountReferralDetailsRoute,
        path: PartnerPortalAccountReferralDetailsUri,
        component: () => import("./AccountReferralDetails.vue"),
        meta: {
          breadcrumbs: PartnerPortalAccountReferralDetailsBreadCrumb,
          title: `Client Details`,
        },
      },
      {
        name: PartnerPortalAccountReferralFormRoute,
        path: PartnerPortalAccountReferralFormUri,
        component: () => import("./AccountReferralForm.vue"),
        meta: {
          breadcrumbs: PartnerPortalAccountReferralFormBreadCrumb,
          title: `New Client`,
        },
      },
    ],
  },
];
