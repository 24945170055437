import { Api } from "@/service/api.service";
import { MfAuthMethod } from "@kinherit/sdk";

interface DeleteMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface DeleteMfAuthMethodResponse {
  [key: string]: never;
}

export const DeleteMfAuthMethodHandler = async (
  message: DeleteMfAuthMethodMessage,
): Promise<DeleteMfAuthMethodResponse> => {
  await Api.resource("kinvault", "/v2/kinvault/mf-auth-method/{mfAuthMethod}", {
    mfAuthMethod: message.mfAuthMethod.id,
  })
    .method("delete")
    .result();

  message.mfAuthMethod.$delete();

  return {};
};
