import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

const PartnerPortalGroupOptions = {
  any: "Any",
  "in-progress": "In Progress",
  "needs-attention": "Needs Attention",
  "on-hold": "On Hold",
  "not-proceeding": "Not Proceeding",
  complete: "Complete",
};

export const ReadAccountReferralForm = () =>
  MasterListFiltersForm({
    name: `read-account-referral`,
    props: {
      size: "is-small" as const,
      isScrollable: true,
      autoCollapseLabel: true,
    },
    updateUrlQuery: true,
    data: () => ({
      name: "",
      // ref: "",
      email: "",
      phoneNumber: "",
      partnerPortalGroup: "any" as string,
    }),
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: `Name`,
            reference: "name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      //ref: [
      //  FormTextField({
      //    props: {
      //      label: `Ref`,
      //    },
      //    models: {
      //      value: "ref",
      //    },
      //  }),
      //],
      status: [
        FormSelectField({
          props: {
            label: `Status`,
            options: PartnerPortalGroupOptions,
            isMultiSelect: false,
            reference: "status",
          },
          models: {
            value: "partnerPortalGroup",
          },
        }),
      ],
    }),
    advancedComponents: () => ({
      email: [
        FormEmailField({
          props: {
            label: `Email address`,
            reference: "email",
          },
          models: {
            value: "email",
          },
        }),
      ],
      phoneNumber: [
        FormTextField({
          props: {
            label: `Phone Number`,
            reference: "phoneNumber",
          },
          models: {
            value: "phoneNumber",
          },
        }),
      ],
    }),
  });
