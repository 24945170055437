// import { Api } from "@/service/api.service";
// import { Address, QueryMask } from "@kinherit/sdk";

// export interface ReadAddressMessage {
//   query?: QueryMask<typeof Address>;
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadAddressResponse {
//   addresses: Array<Address>;
// }

// export const ReadAddressHandler = async (
//   message: ReadAddressMessage,
// ): Promise<ReadAddressResponse> => {
//   const request = Api.resource("core", "/v2/core/select/address")
//     .method("get")

//     .paginate(message.pagination ?? false);
//   request.buildQuery(Address).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     addresses: Address.$inflate(response.address, undefined, response.$rootIds),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Address } from "@kinherit/sdk";

export const ReadAddressHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/address",
  method: "get",
  rootModel: Address,
  parse: () => ({}),
});
