<template>
  <div class="help-wrapper">
    <MobileNavigationSelect
      v-if="navigationItems.length > 1"
      label="Navigation"
      :options="navigationItems"
      :selected="
        navigationItems.findIndex((item) => item.route === $route.name)
      "
    />
    <HelpSidebarPage
      is-plain
      title="Help & FAQs"
      description="Answers to common questions about our Estate Planning and Kinvault services."
      :items="sidebar.items"
      v-model:item="sidebar.selected"
    >
      <RouterChildView />
    </HelpSidebarPage>
  </div>
</template>

<script lang="ts">
import { HelpSidebarItem } from "@/config/types";
import { AuthService } from "@/service/auth.service";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  HelpFaqEstatePlanningRoute,
  HelpFaqKinvaultRoute,
  HelpIndexRoute,
} from ".";
import HelpSidebarPage from "../component/HelpSidebarPage.vue";

import MobileNavigationSelect from "@/shared/component/MobileNavigationSelect.vue";

export default defineComponent({
  name: HelpIndexRoute,
  mixins: [
    AuthService.mixin({
      module: "logged-in",
    }),
  ],
  components: { HelpSidebarPage, RouterChildView, MobileNavigationSelect },
  data: () => ({
    sidebar: {
      items: [
        {
          label: "Estate Planning" as const,
          route: { name: HelpFaqEstatePlanningRoute },
        },
        {
          label: "Kinvault" as const,
          route: { name: HelpFaqKinvaultRoute },
        },
      ] satisfies HelpSidebarItem[],
      selected: null as HelpSidebarItem | null,
    },
  }),
  computed: {
    navigationItems(): HelpSidebarItem[] {
      return this.sidebar.items;
    },
  },
});
</script>
