import { ActiveSession } from "@/module/auth/model/active-session.model";
import { SettingsIndexRoute } from "@/module/core/page/settings";

import { PartnerPortalIndexRoute } from "@/module/dashboard/page";
import { HelpFaqEstatePlanningRoute } from "@/module/help/page";
import { NavbarOptionsConfig } from "@kinherit/framework/component.layout/header/types";
import { DevConfig } from "./dev.config";
import { DangerDialog } from "./dialog.config";

export const DefaultNavbarConfig: ReturnType<NavbarOptionsConfig> = {
  elements: {
    logo: {
      onClick: DevConfig,
    },
  },
  content: {
    logo: "https://static.kinherit.co.uk/brand/kinherit/Kinherit_Logo_Blue_cropped.svg",
    menu: [
      {
        label: "Dashboard",
        show: () => null !== ActiveSession.$getInstance(),
        click: async (event) =>
          window.Kernel.visitRoute(
            {
              name: PartnerPortalIndexRoute,
            },
            event.ctrlKey,
          ),
      },
      {
        label: "FAQs",
        show: () => null !== ActiveSession.$getInstance(),
        click: async (event) =>
          window.Kernel.visitRoute(
            {
              name: HelpFaqEstatePlanningRoute,
            },
            event.ctrlKey,
          ),
      },
    ],
    buttons: [
      {
        label: "Settings",
        show: () => null !== ActiveSession.$getInstance(),
        click: async (event) =>
          window.Kernel.visitRoute(
            {
              name: SettingsIndexRoute,
            },
            event.ctrlKey,
          ),
      },
      {
        label: "Logout",
        show: () => null !== ActiveSession.$getInstance(),
        click: async () => {
          await DangerDialog({
            dialog: {
              title: "Logout",
              message: "Are you sure you want to logout?",
            },
            button: {
              ok: {
                text: "Logout",
              },
            },
          });
          // window.Kernel.ActionBus.execute("auth/session/logout", {});
          await window.Kernel.ActionBus.auth.session.logout();
        },
      },
    ],
  },
};

export const NavbarConfig: NavbarOptionsConfig = () => DefaultNavbarConfig;
