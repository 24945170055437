import { ChangePasswordHandler } from "@/module/dashboard/action/change-password/change-password-handler.action";
import { ReadKintinAccessHandler } from "@/module/dashboard/action/read-kintin-access.action";
import { RecordKintinAccessHandler } from "@/module/dashboard/action/record-kintin-access.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

// export const Actions = {
//   // kinvaults
//   "partner-portal/kintin-access/read": ReadKintinAccessHandler,
//   "partner-portal/kintin-access/record": RecordKintinAccessHandler,
//   // password
//   "partner-portal/settings/change-password": ChangePasswordHandler,
// };

export const DashboardActionBus = DefineActionBus({
  name: "Dashboard",
  actions: {
    kintinAccess: DefineActionBus({
      name: "Dashboard.KintinAccess",
      actions: {
        read: ReadKintinAccessHandler,
        record: RecordKintinAccessHandler,
      },
    }),
    settings: DefineActionBus({
      name: "Dashboard.Settings",
      actions: {
        changePassword: ChangePasswordHandler,
      },
    }),
  },
});
