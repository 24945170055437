<template>
  <div class="mobile-navigation-select" v-if="computedRoutes.isNotEmpty()">
    <SelectField
      v-model:value="computedValue"
      :show-clear-button="false"
      :label="label"
      :options="computedRoutes"
      :map-options="{
        label: (option: RouteInterferWithDepth) =>
          `${`-`.repeat(option.depth)} ${option.meta?.title ?? ``}`,
        value: 'name',
      }"
    ></SelectField>
  </div>
</template>

<script lang="ts">
import { SelectField } from "@kinherit/framework/component.input/select-field";
import { RouteInterface } from "@kinherit/framework/core/route-config";
import { PropType, defineComponent } from "vue";

type RouteInterferWithDepth = RouteInterface & { depth: number };

export default defineComponent({
  name: "MobileNavigationSelect",
  components: { SelectField },
  props: {
    label: {
      type: String,
      default: "Select Sub Section",
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
    /**
     * The maxiumum depth of nested routes to include
     * @default Infinity
     */
    maxDepth: {
      type: Number,
      default: 1,
    },
    /**
     * Start the navigation for the given routes
     */
    fromRoutes: {
      type: Array as PropType<Array<string> | null>,
      default: null,
    },
    /**
     * Start the navigation from the current route
     */
    fromCurrentRoute: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedRoutes(): RouteInterferWithDepth[] {
      const routes = this.computedStartRoutes;

      const addChildren = (route: RouteInterface, depth: number = 1) => {
        if (depth > this.maxDepth) {
          return;
        }

        const index = routes.findIndex((r) => r.name === route.name);

        if (index === -1) {
          routes.push(
            ...(route.children?.map((child) => ({ ...child, depth })) ?? []),
          );
        } else {
          routes.splice(
            index + 1,
            0,
            ...(route.children?.map((child) => ({ ...child, depth })) ?? []),
          );
        }

        route.children?.map((child) => {
          return addChildren(child, depth + 1);
        });
      };

      routes.forEach((route) => addChildren(route));

      return routes;
    },
    computedStartRoutes(): RouteInterferWithDepth[] {
      if (this.fromCurrentRoute) {
        return (
          (
            this.$router.getRoutes().find((route) => {
              return route?.children
                ?.pluck("name")
                .includes(this.$route.name as string);
            }) ?? {}
          ).children
            ?.cast<RouteInterferWithDepth>()
            .filter((route) => route.meta?.title)
            .map((route) => ({
              ...route,
              depth: 0,
            })) ?? []
        );
      }

      return this.$router
        .getRoutes()
        .filter(
          (route) => this.fromRoutes?.includes(route.name as string) ?? true,
        )
        .cast<RouteInterferWithDepth>()
        .filter((route) => route.meta?.title)
        .filter((route, _, all) => {
          return !all.some((loopRoute) => this.isChild(loopRoute, route.name));
        })
        .map((route) => ({
          ...route,
          depth: 0,
        }));
    },
    computedValue: {
      get(): RouteInterface | null {
        return (
          this.computedRoutes.find(
            (route) => route.name === this.$route.name,
          ) ?? null
        );
      },
      set(route: RouteInterface | null) {
        if (null === route) {
          return;
        }

        window.Kernel.visitRoute({
          name: route.name,
          params: this.$route.params,
        });
      },
    },
  },
  methods: {
    isChild(potentialParent: RouteInterface, target: string): boolean {
      return (
        potentialParent.children
          ?.map((child) => {
            if (child.name === target) {
              return true;
            }

            return this.isChild(child, target);
          })
          .includes(true) ?? false
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.mobile-navigation-select {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
</style>
