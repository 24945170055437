import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadInvitesForm = () =>
  MasterListFiltersForm({
    name: `read-account-referral`,
    props: {
      size: "is-small" as const,
      isScrollable: true,
      autoCollapseLabel: true,
    },
    updateUrlQuery: true,
    data: () => ({
      name: "",
      email: "",
      status: [] as (
        | "notsent"
        | "sent"
        | "complete"
        | "failed"
        | "declined"
        | "cancelled"
      )[],
    }),
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: `Name`,
            reference: "name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      status: [
        FormAutoCompleteField({
          props: {
            options: {
              notsent: "Not Sent",
              sent: "Sent",
              complete: "Complete",
              failed: "Failed",
              declined: "Declined",
              cancelled: "Cancelled",
            },
            isMultiSelect: true,
            label: `Status`,
            reference: "status",
          },
          models: {
            value: "status",
          },
        }),
      ],
    }),
    advancedComponents: () => ({
      email: [
        FormEmailField({
          props: {
            label: `Email address`,
            reference: "email",
          },
          models: {
            value: "email",
          },
        }),
      ],
    }),
  });
