export const PartnerPortalIndexBreadCrumb = () => [
  {
    text: "Dashboard",
    route: { name: "PartnerPortalIndex" },
  },
];

export const PartnerPortalKinvaultMasterListBreadCrumb = () => [
  ...PartnerPortalIndexBreadCrumb(),
  {
    text: "Kinvaults",
    route: { name: "PartnerPortalKinvaultMasterList" },
  },
];
