import { ThemeColorType } from "@kinherit/framework/theme/prop/color";
import { AccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";

type statusObj = {
  text: string;
  color: ThemeColorType;
};
export const ParterPortalMixin = defineComponent({
  name: "ParterPortalMixin",
  methods: {
    calculateAccountReferralStatus(item: AccountReferral) {
      let statusObj: statusObj = { text: "New Referral", color: "is-plain" };

      const partnerPortalGroup = item.kintin
        ? item.kintin.status?.data?.partnerPortalGroup
        : item.lead?.status
          ? item.lead?.status?.data?.partnerPortalGroup
          : null;

      if (item.kintin) {
        if (partnerPortalGroup === "not-proceeding") {
          statusObj = { text: "Not Proceeding", color: "is-light-grey" };
        } else if (partnerPortalGroup === "on-hold") {
          statusObj = {
            text: "On Hold",
            color: "is-kinherit-ochre",
          };
        } else if (
          partnerPortalGroup === "needs-attention" ||
          !item.kintin.isActive
        ) {
          statusObj = {
            text: `Help Needed${!item.kintin.isActive ? " (Inactive)" : ""}`,
            color: "is-warning",
          };
        } else if (partnerPortalGroup === "complete") {
          statusObj = { text: "Completed", color: "is-success" };
        } else if (partnerPortalGroup === "in-progress") {
          statusObj = { text: "In Progress", color: "is-teal" };
        }
      } else if (item.lead) {
        if (partnerPortalGroup === "not-proceeding") {
          statusObj = { text: "Not Proceeding", color: "is-light-grey" };
        } else if (partnerPortalGroup === "on-hold") {
          statusObj = {
            text: "On Hold",
            color: "is-kinherit-ochre",
          };
        } else if (partnerPortalGroup === "needs-attention") {
          statusObj = {
            text: "Help Needed",
            color: "is-warning",
          };
        } else if (partnerPortalGroup === "complete") {
          statusObj = { text: "Completed", color: "is-success" };
        } else if (partnerPortalGroup === "in-progress") {
          statusObj = { text: "In Progress", color: "is-teal" };
        }
      }

      return statusObj;
    },
  },
});
