<template>
  <router-link class="dashboard-tile" :to="{ name: route }">
    <Icon :icon="icon" size="is-large" />
    <h3 class="is-display is-5 m-2">{{ title }}</h3>
    <small class="is-block m-3" v-if="strap">
      {{ strap }}
    </small>
  </router-link>
</template>

<script lang="ts">
import { Icon } from "@kinherit/framework/component.display/icon";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "DashboardTile",
  components: { Icon },
  props: {
    title: {
      type: String,
      required: true,
    },
    strap: {
      type: String,
      required: true,
    },
    icon: {
      type: String as PropType<ThemeIconName>,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-tile {
  border: 1px solid $color-grey-lighter;
  border-radius: $radius;
  padding: 1em;
  text-align: center;
  height: 100%;
  display: block;
  color: $dark-grey;

  &:hover {
    background-color: $color-grey-lightest;
    cursor: pointer;
  }
  &:hover {
    text-decoration: none;
  }
}
</style>
