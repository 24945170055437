import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { IsNotNull } from "@kinherit/orm";
import { IMfAuthMethod, MfAuthMethod } from "@kinherit/sdk";

export interface ReadMfAuthMethodsMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IMfAuthMethod;
    direction: "asc" | "desc";
  };
}

interface ReadMfAuthMethodsResponse {
  mfAuthMethods: Array<MfAuthMethod>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadMfAuthMethodsHandler = async (
  message: ReadMfAuthMethodsMessage,
): Promise<ReadMfAuthMethodsResponse> => {
  const request = Api.resource("kinvault", "/v2/kinvault/mf-auth-method")
    .method("get")
    .sort(message.sort)
    .paginate(message.pagination);
  request.buildQuery(MfAuthMethod).where({
    setupCompleteAt: IsNotNull(),
  });

  const response = await request.result();

  return {
    mfAuthMethods: MfAuthMethod.$inflate(response.mfAuthMethod),
    pagination: getPagination(response),
  };
};
