import { Api } from "@/service/api.service";

interface ValidateTokenMessage {
  token: string;
}

interface ValidateTokenResult {
  [key: string]: never;
}

export const ValidateTokenHandler = async (
  message: ValidateTokenMessage,
): Promise<ValidateTokenResult> => {
  await Api.resource(
    "auth",
    "/v2/auth/change-password/token/{token}/validation",
    message,
  )
    .method("put")
    .result();

  return {};
};
