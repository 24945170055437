import { PartnerPortalIndexBreadCrumb } from "@/module/dashboard/breadcrumbs";

export const PartnerPortalInviteMasterListBreadCrumb = () => [
  ...PartnerPortalIndexBreadCrumb(),
  {
    text: "Invites",
    route: { name: "PartnerPortalInviteMasterList" },
  },
];

export const PartnerPortalInviteFormBreadCrumb = () => [
  ...PartnerPortalInviteMasterListBreadCrumb(),
  {
    text: "Invite a Client",
    route: { name: "PartnerPortalInviteForm" },
  },
];
