import { SessionData } from "@/module/auth/model/session.model";
import { Singleton } from "@kinherit/orm";

export interface ActiveSessionData {
  username: string;
  token: string;
  mfaRequired: boolean;
  user: string;
  accountType: "IntroducerContact";
}

export class ActiveSession extends Singleton<ActiveSessionData> {
  readonly $storage = "local";

  public get token(): string {
    return this.$data.token;
  }

  public get username(): string {
    return this.$data.username;
  }

  public get mfaRequired(): boolean {
    return this.$data.mfaRequired;
  }

  public static fromSession(session: SessionData): ActiveSession {
    return new this({
      username: session.id,
      token: session.token,
      mfaRequired: session.mfaRequired,
      user: session.user,
      accountType: session.accountType,
    });
  }

  public get user(): string {
    return this.$data.user;
  }

  public get accountType(): "IntroducerContact" {
    return this.$data.accountType;
  }
}
