import { ChangePasswordFromTokenHandler } from "@/module/auth/action/change-password/change-password-from-token-handle.action";
import { SetPasswordFromTokenHandler } from "@/module/auth/action/change-password/set-password-from-token-handler.action";
import { ValidateTokenHandler } from "@/module/auth/action/change-password/validate-token-handle.action";
import { ReadSessionHandler } from "@/module/auth/action/session/read-session.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CompleteMultiFactorAuthHandler } from "./multi-factor/complete-multi-factor-auth.action";
import { RequestMultiFactorAuthHandler } from "./multi-factor/request-multi-factor-auth.action";
import { CompleteSessionHandler } from "./session/complete-session.action";
import { LogoutSessionHandler } from "./session/logout-session.action";
import { RequestSessionHandler } from "./session/request-session.action";

// export const Actions = {
//   // multi-factor login
//   "auth/multi-factor/request": RequestMultiFactorAuthHandler,
//   "auth/multi-factor/complete": CompleteMultiFactorAuthHandler,
//   // session
//   "auth/session/request": RequestSessionHandler,
//   "auth/session/complete": CompleteSessionHandler,
//   "auth/session/logout": LogoutSessionHandler,
//   "auth/session/read": ReadSessionHandler,
//   // change password
//   "auth/reset-password": ChangePasswordFromTokenHandler,
//   "auth/validate-token": ValidateTokenHandler,
//   "auth/set-password": SetPasswordFromTokenHandler,
// };

export const AuthActionBus = DefineActionBus({
  name: "Auth",
  actions: {
    multiFactor: DefineActionBus({
      name: "Auth.MultiFactor",
      actions: {
        request: RequestMultiFactorAuthHandler,
        complete: CompleteMultiFactorAuthHandler,
      },
    }),
    session: DefineActionBus({
      name: "Auth.Session",
      actions: {
        request: RequestSessionHandler,
        complete: CompleteSessionHandler,
        logout: LogoutSessionHandler,
        read: ReadSessionHandler,
      },
    }),
    changePassword: DefineActionBus({
      name: "Auth.ChangePassword",
      actions: {
        reset: ChangePasswordFromTokenHandler,
        validateToken: ValidateTokenHandler,
        setPassword: SetPasswordFromTokenHandler,
      },
    }),
  },
});
