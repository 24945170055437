import {
  AlertDialogRequest,
  OpenAlertDialog,
} from "@kinherit/framework/global/dialog";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { DeepPartial } from "@kinherit/ts-common";

export const DangerDialog = (request: DeepPartial<AlertDialogRequest> = {}) =>
  OpenAlertDialog({
    ...request,
    dialog: {
      title: "Danger",
      icon: ThemeIconName.Cross,
      message: "Are you sure you want to continue?",
      ...((request.dialog as any) ?? {}),
    },
    button: {
      ok: {
        text: "OK",
        color: "is-danger",
        ...(request.button?.ok ?? {}),
      },
      cancel: {
        text: "Cancel",
        ...(request.button?.cancel ?? {}),
      },
    },
  });

export const WarningDialog = (request: DeepPartial<AlertDialogRequest> = {}) =>
  OpenAlertDialog({
    ...request,
    dialog: {
      title: "Warning",
      icon: ThemeIconName.WarningTriangle,
      message: "Are you sure you want to continue?",
      ...((request.dialog as any) ?? {}),
    },
    button: {
      ok: {
        text: "OK",
        color: "is-warning",
        ...(request.button?.ok ?? {}),
      },
      cancel: {
        text: "Cancel",
        ...(request.button?.cancel ?? {}),
      },
    },
  });

export const ConfirmDialog = (request: DeepPartial<AlertDialogRequest> = {}) =>
  OpenAlertDialog({
    ...request,
    dialog: {
      title: "Please confirm",
      icon: ThemeIconName.Info,
      message: "Are you sure you want to continue?",
      ...((request.dialog as any) ?? {}),
    },
    button: {
      ok: {
        text: "OK",
        color: "is-info",
        ...(request.button?.ok ?? {}),
      },
      cancel: {
        text: "Cancel",
        ...(request.button?.cancel ?? {}),
      },
    },
  });
