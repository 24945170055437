<template>
  <p>
    Hello
    {{ firstName }},
  </p>
  <p>
    Your adviser
    {{ adviserName ? adviserName : "" }}
    from
    {{ brandedKinvaultName ? brandedKinvaultName : "" }}
    has asked us to send you a link to your complimentary
    <strong>Kinvault</strong>.
  </p>
  <p>
    They’re paying for you to have your own secure vault, to help you organise
    your important life admin in a way that will also help your family step into
    your shoes if its ever needed.
  </p>
  <p>
    <strong
      >To check it out,
      <a :href="link" target="_blank">click here</a>
      or to find out more, read-on.</strong
    >
  </p>
  <p>
    The
    <a href="https://www.kinvault.com">Kinvault</a>
    helps you keep track of all your legal, financial and practical information
    in one place. If you then become ill, or when you die, it makes sure all
    this information is securely passed on to the people who need it. This saves
    time, money and the distress so often experienced during probate.
  </p>
  <p>
    <strong>How it helps <em>you</em> </strong>
  </p>
  <ul>
    <li>A powerful tool for getting your life admin organised</li>
    <li>
      Provides one secure, online place for all your important information
    </li>
    <li>Gives you peace of mind you’ve done all you can for your family</li>
  </ul>
  <p>
    <strong> How it helps your family</strong>
  </p>
  <ul>
    <li>
      Cuts probate time and reduces stress by giving them all they need after
      death
    </li>
    <li>Ensures they inherit ALL your assets (not just those they find)</li>
    <li>
      Provides practical instructions to deal with utilities, vehicles, pet care
      etc
    </li>
  </ul>
  <p>
    The Kinvault has been developed by STEP-qualified Will &amp; Trust experts,
    <a href="https://www.kinherit.co.uk">Kinherit</a>
    (an affiliate of the
    <a href="https://consumerduty.org/">Consumer Duty Alliance</a>) and is
    trusted by thousands of regulated financial advisers across the UK.
  </p>
  <p>
    Take a couple of minutes to set up your password and have a look around by
    clicking the link below – it’s one of those things that makes more sense
    once you see it.
  </p>
  <p>
    <a :href="link">{{ link }}</a>
  </p>

  <p>Enjoy exploring!</p>
  <p>Kind regards, <br />The Kinvault Team</p>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "BrandedKinvaultInvitation",
  props: {
    firstName: {
      type: String as PropType<string | null>,
      default: null,
    },
    adviserName: {
      type: String as PropType<string | null>,
      default: null,
    },
    brandedKinvaultName: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: true,
    },
  },
});
</script>
