import { Api } from "@/service/api.service";

interface ChangePasswordFromTokenMessage {
  username: string;
}

interface ChangePasswordFromTokenResult {
  [key: string]: never;
}

export const ChangePasswordFromTokenHandler = async (
  message: ChangePasswordFromTokenMessage,
): Promise<ChangePasswordFromTokenResult> => {
  await Api.resource(
    "auth",
    "/v2/auth/change-password/external/{username}/{accountType}",
    {
      username: message.username,
      accountType: "introducer",
    },
  )
    .method("put")
    .result();

  return {};
};
