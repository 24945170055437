<template>
  <div class="help-faq-estate-planning">
    <Card title="Making Referrals">
      <FaqAccordian
        question="How do I refer a client for estate planning services?"
      >
        Simply fill out the
        <span
          @click="handleClientFormClick"
          class="is-clickable has-text-weight-bold has-text-positive"
          title="Go to Form"
          >form here</span
        >
        (assuming you have the correct permissions). You will need the first
        name, last name, email address and phone number of the client.
      </FaqAccordian>
      <FaqAccordian
        question="Can I submit referrals on behalf of another adviser?"
      >
        Yes – simply select the appropriate person under the Referral Details
        section. If someone isn’t listed, please contact
        <a href="mailto:sales@kinherit.co.uk">sales@kinherit.co.uk</a>.
      </FaqAccordian>
      <FaqAccordian question="What is a referral code?">
        Referral codes help us track the source of the referral. Whilst most
        people will only see one referral code, if you have multiple codes
        available to you, please contact
      </FaqAccordian>
      <FaqAccordian question="What if the case is urgent?">
        If the case is urgent, such as involving a terminal illness, we can
        escalate the case internally. Please provide as much information as
        possible in the Further Information section of the referral form. Using
        the referral form is quicker than emailing an Estate Planner directly,
        as incoming requests are constantly monitored.
      </FaqAccordian>
      <FaqAccordian question="What happens when a case is escalated?">
        If a case is flagged as escalated in our system, additional daily checks
        are performed by a senior management team member. This ensures no
        accidental delays, and pushes the planning through as quickly as
        possible. Please note, if a case is escalated, it may be assigned to an
        Estate Planner who has sufficient short term capacity, as it may be your
        usual assigned Estate Planner is on annual leave, or has no free
        appointment slots.
      </FaqAccordian>
    </Card>

    <Card title="Monitoring Client Progress">
      <FaqAccordian
        question="Why is my client showing as “complete” when I know other things need to be done?"
      >
        The partner portal may show a client as being complete even when other
        long running tasks are still yet to be done – for instance, if a client
        is taking out LPAs, these can often take months till the Office of the
        Public Guardian deems them registered. Additionally, we may be waiting
        on the Land Registry, which can add delays.
      </FaqAccordian>
      <FaqAccordian question="My client is showing as “not proceeding” – why?">
        There are multiple reasons why a client may not choose to proceed with
        us – often the actual reason can contain personal information, so we do
        not share this reason by default. If you have particular questions about
        why a client chose not to proceed with us, please contact
        <a href="mailto:sales@kinherit.co.uk">sales@kinherit.co.uk</a>
      </FaqAccordian>
      <FaqAccordian
        question="My client is showing as “on hold” – what does that mean?"
      >
        A case will be put on hold when the client is still interested in
        proceeding, but we’ve been specifically asked to follow up on a later
        date – for instance, they may be going out the country for a few weeks.
      </FaqAccordian>
      <FaqAccordian question="My client is showing as “help needed”?">
        A case can enter this status if there is missing critical information,
        such as an initial phone number, or where we have tried to contact the
        client, but have been unable to contact them. Often, a small
        intervention from the person who introduced the client can help get
        things get back on track.
      </FaqAccordian>
    </Card>
  </div>
</template>

<script lang="ts">
import { PartnerPortalAccountReferralFormRoute } from "@/module/clients/page";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { HelpFaqEstatePlanningRoute } from ".";
import FaqAccordian from "../component/FaqAccordian.vue";
export default defineComponent({
  name: HelpFaqEstatePlanningRoute,
  components: { Card, FaqAccordian },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
  ],
  methods: {
    handleClientFormClick() {
      const permissions: typeof AuthService.portalPermissions =
        this.$auth.portalPermissions;
      if (!permissions.invitationsCreate) {
        SnackBarService.toast({
          text: "You do not have permission to create add referrals. Please contact support.",
          type: "error",
        });
        return;
      }
      window.Kernel.visitRoute({
        name: PartnerPortalAccountReferralFormRoute,
      });
    },
  },
});
</script>
