<template>
  <RouterChildView />
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { PartnerPortalInviteIndexRoute } from ".";

export default defineComponent({
  name: PartnerPortalInviteIndexRoute,
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
  ],
  components: {
    RouterChildView,
  },
});
</script>
