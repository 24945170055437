// import { Api } from "@/service/api.service";
// import { IKintin, Kintin, QueryMask } from "@kinherit/sdk";

// export interface ReadKintinMessage {
//   query?: QueryMask<typeof Kintin> | Array<QueryMask<typeof Kintin>>;
//   sort?: {
//     by: keyof IKintin;
//     direction: "asc" | "desc";
//   };
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadKintinResponse {
//   kintins: Array<Kintin>;
// }

// export const ReadKintinHandler = async (
//   message: ReadKintinMessage,
// ): Promise<ReadKintinResponse> => {
//   const request = Api.resource("core", "/v2/core/select/kintin")
//     .method("get")

//     .sort({
//       sortBy: message.sort?.by ?? "friendlyName",
//       sortDirection: message.sort?.direction ?? "asc",
//     })
//     .paginate(message.pagination ?? false);

//   request.buildQuery(Kintin).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     kintins: Kintin.$inflate(response.kintin, message.sort, response.$rootIds),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk";

export const ReadKintinHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/kintin",
  method: "get",
  rootModel: Kintin,
  parse: () => ({}),
});
