import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KintinAccess } from "@kinherit/sdk";

// export const RecordKintinAccessHandler = async (message: {
//   kintinAccess: string | KintinAccess;
// }) => {
//   const request = Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/kintin-access/{kintinAccess}",
//     {
//       kintinAccess: message.kintinAccess,
//     },
//   ).method("get");

//   const response = await request.result();

//   return {
//     kintinAccess: KintinAccess.$inflate(response.kintinAccess),
//     kintin: Kintin.$inflate(response.kintin),
//     introducerContact: IntroducerContact.$inflate(response.introducerContact),
//     accountReferral: AccountReferral.$inflate(response.accountReferral),
//     accountReferralCode: AccountReferralCode.$inflate(
//       response.accountReferralCode,
//     ),
//     profile: Profile.$inflate(response.profile),
//   };
// };

export const RecordKintinAccessHandler = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/kintin-access/{kintinAccess}",
  method: "get",
  rootModel: KintinAccess,
  parse: (message: { kintinAccess: string | KintinAccess }) => ({
    params: {
      kintinAccess: message.kintinAccess,
    },
  }),
});
