import {
  PartnerPortalInviteFormBreadCrumb,
  PartnerPortalInviteMasterListBreadCrumb,
} from "@/module/invites/breadcrumbs";

import { RouteInterface } from "@kinherit/framework/core/route-config";

export const PartnerPortalInviteIndexRoute = "PartnerPortalInviteIndex";

export const PartnerPortalInviteMasterListRoute =
  "PartnerPortalInviteMasterList";

export const PartnerPortalInviteFormRoute = "PartnerPortalInviteForm";
const PartnerPortalInviteFormUri = "invite/new";

export const Routes: Array<RouteInterface> = [
  {
    name: PartnerPortalInviteIndexRoute,
    path: "/invites",
    component: () => import("./Index.vue"),
    redirect: { name: PartnerPortalInviteMasterListRoute },
    children: [
      {
        name: PartnerPortalInviteMasterListRoute,
        path: "/Invites",
        component: () => import("./InviteMasterList.vue"),
        meta: {
          breadcrumbs: PartnerPortalInviteMasterListBreadCrumb,
          title: `Invites`,
        },
      },
      {
        name: PartnerPortalInviteFormRoute,
        path: PartnerPortalInviteFormUri,
        component: () => import("./InviteForm.vue"),
        meta: {
          breadcrumbs: PartnerPortalInviteFormBreadCrumb,
          title: `Invite a Client`,
        },
      },
    ],
  },
];
