import { Api } from "@/service/api.service";
import { MfAuthMethod } from "@kinherit/sdk";

interface CreateMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface CreateMfAuthMethodResponse {
  mfAuthMethod: MfAuthMethod;
}

export const CreateMfAuthMethodHandler = async (
  message: CreateMfAuthMethodMessage,
): Promise<CreateMfAuthMethodResponse> => {
  const credential = message.mfAuthMethod.credential;
  const respense = await Api.resource(
    "kinvault",
    "/v2/kinvault/mf-auth-method",
    {
      credential,
    },
  )
    .method("post")
    .body({
      type: message.mfAuthMethod.$data.type,
      alias: message.mfAuthMethod.$data.alias,
      device: message.mfAuthMethod.$data.device,
      key: message.mfAuthMethod.$data.key,
      credential: message.mfAuthMethod.$data.credential,
      setupCompleteAt: message.mfAuthMethod.$data.setupCompleteAt,
    })
    .result();

  const mfAuthMethod = MfAuthMethod.$inflate(respense.mfAuthMethod).first();

  if (!mfAuthMethod) {
    throw new Error("Failed to create auth method");
  }

  message.mfAuthMethod.$delete();

  return {
    mfAuthMethod,
  };
};
