import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

import BrandedKinvaultInvitation from "@/module/invites/page/BrandedKinvaultInvitation.vue";
import { FormComponentWrapper } from "@kinherit/framework/form-builder/core/component-wrapper";

export const PreviewInviteForm = ({
  firstName,
  adviserName,
  brandedKinvaultName,
  link,
}: {
  firstName: string;
  adviserName: string;
  brandedKinvaultName: string;
  link: string;
}) =>
  defineForm({
    name: "preview-invite-form",
    data: () =>
      ({
        firstName,
        adviserName,
        brandedKinvaultName,
        link,
      }) as any,

    formAreas: (data) => [
      defineFormArea({
        name: "preview-invite-form-area",
        data,
        template: GridLayout([["previewEmail"]]),
        components: () => ({
          previewEmail: [
            FormComponentWrapper(BrandedKinvaultInvitation)({
              props: {
                firstName: data.firstName,
                adviserName: data.adviserName,
                brandedKinvaultName: data.brandedKinvaultName,
                link: data.link,
              },
            }),
          ],
        }),
      }),
    ],
  });
