// import { Api } from "@/service/api.service";
// import { IProduct, Product, QueryMask } from "@kinherit/sdk";

// export interface ReadProductMessage {
//   query?: QueryMask<typeof Product>;
//   sort?: {
//     by: keyof IProduct;
//     direction: "asc" | "desc";
//   };
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadProductResponse {
//   products: Array<Product>;
// }

// export const ReadProductHandler = async (
//   message: ReadProductMessage,
// ): Promise<ReadProductResponse> => {
//   const request = Api.resource("core", "/v2/core/select/product")
//     .method("get")

//     .sort({
//       sortBy: message.sort?.by ?? "publicText",
//       sortDirection: message.sort?.direction ?? "asc",
//     })
//     .paginate(message.pagination ?? false);

//   request.buildQuery(Product).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     products: Product.$inflate(
//       response.product,
//       message.sort,
//       response.$rootIds,
//     ),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Product } from "@kinherit/sdk";

export const ReadProductHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/product",
  method: "get",
  rootModel: Product,
  parse: () => ({}),
});
