import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AppOptionsConfig } from "@kinherit/framework/core/internal-components/app/types";

export const AppConfig: AppOptionsConfig = () => ({
  elements: {
    page: {
      class: [
        ...(null === ActiveSession.$getInstance()
          ? ["login-screen-image"]
          : []),
      ],
    },
  },
});
