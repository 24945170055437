import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { ColumnLayout } from "@kinherit/framework/component.layout/dynamic-layout/layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const ForgotForm = (data = { username: "" }) =>
  defineForm({
    name: "forgot-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: {
          children: [ColumnLayout(["username" as const])],
        },
        components: () => ({
          username: [
            FormEmailField({
              props: {
                label: "Email Address",
                validators: ["required"],
                autocomplete: "username",
                message: "Please enter your email address.",
                class: "email-field",
                reference: "username",
              },
              models: {
                value: "username",
              },
            }),
          ],
        }),
      }),
    ],
  });
