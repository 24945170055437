import { ActiveSession } from "@/module/auth/model/active-session.model";
import { Session } from "@/module/auth/model/session.model";
import { Api } from "@/service/api.service";

interface RequestSessionMessage {
  username: string;
  password: string;
}

interface RequestSessionResponse {
  session: Session;
  activeSession: ActiveSession;
}

export const RequestSessionHandler = async (
  message: RequestSessionMessage,
): Promise<RequestSessionResponse> => {
  const response = await Api.resource("auth", "/v2/auth/login/partner")
    .method("put")
    .body(message)
    .result();

  const session = Session.$create({
    id: message.username,
    ...response,
  });

  session.$persist();

  const activeSession = ActiveSession.fromSession({
    ...session.$data,
  }).$persist();

  if (false === session.mfaRequired) {
    // await window.Kernel.ActionBus.execute("auth/session/complete", {
    //   activeSession,
    // });
    await window.Kernel.ActionBus.auth.session.complete();
  }

  return { session, activeSession };
};
