// import { Api } from "@/service/api.service";
// import { IRole, Role } from "@kinherit/sdk";

// export interface ReadRoleMessage {
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
//   sort?: {
//     by: keyof IRole;
//     direction: "asc" | "desc";
//   };
// }

// interface ReadRoleResponse {
//   roles: Array<Role>;
// }

// export const ReadRoleHandler = async (
//   message: ReadRoleMessage,
// ): Promise<ReadRoleResponse> => {
//   const response = await Api.resource("core", "/v2/core/select/role")
//     .method("get")

//     .sort({
//       sortBy: message.sort?.by ?? "role",
//       sortDirection: message.sort?.direction ?? "asc",
//     })
//     .paginate(message.pagination ?? false)
//     .result();

//   return {
//     roles: Role.$inflate(response.role, message.sort, response.$rootIds),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Role } from "@kinherit/sdk";

export const ReadRoleHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/role",
  method: "get",
  rootModel: Role,
  parse: () => ({}),
});
