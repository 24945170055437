import { AuthActionBus } from "@/module/auth/action/action-bus";
import { ClientsActionBus } from "@/module/clients/action/action-bus";
import { CoreActionBus } from "@/module/core/action/action-bus";
import { DashboardActionBus } from "@/module/dashboard/action/action-bus";
import { HelpActionBus } from "@/module/help/action/action-bus";
import { InvitesActionBus } from "@/module/invites/action/action-bus";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const ActionBus = DefineActionBus({
  name: "ActionBus",
  actions: {
    auth: AuthActionBus,
    clients: ClientsActionBus,
    core: CoreActionBus,
    dashboard: DashboardActionBus,
    help: HelpActionBus,
    invites: InvitesActionBus,
  },
});
