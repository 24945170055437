import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/index";

// export const UpdateInviteHandler = async (message: {
//   brandedKinvaultInvitation: BrandedKinvaultInvitation;
//   status: "sent" | "complete" | "failed" | "declined" | "cancelled" | "notsent";
// }) => {
//   await Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/branded-kinvault-invitation/{brandedKinvaultInvitation}",
//     message,
//   )
//     .method("patch")
//     .body({
//       status: message.status,
//     })
//     .result();
// };

export const UpdateInviteHandler = DefineWriteAction({
  interface: "partner-portal",
  resource:
    "/v2/partner-portal/branded-kinvault-invitation/{brandedKinvaultInvitation}",
  method: "patch",
  parse: (message: {
    brandedKinvaultInvitation: BrandedKinvaultInvitation;
    status:
      | "sent"
      | "complete"
      | "failed"
      | "declined"
      | "cancelled"
      | "notsent";
  }) => ({
    params: message,
    body: {
      status: message.status,
    },
  }),
});
