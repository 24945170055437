<template>
  <div class="page-layout">
    <slot name="header">
      <h1 v-if="title" class="is-display">{{ title }}</h1>
      <p v-if="strap" class="lead">{{ strap }}</p>
    </slot>
    <slot></slot>
    <slot name="footer"></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "PageLayout",
  props: {
    title: {
      type: String,
      required: false,
    },
    strap: {
      type: String,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.page-layout {
  padding-left: var(--card-padding-left);
  padding-right: var(--card-padding-right);
  padding-top: var(--card-padding-top);
  padding-bottom: var(--card-padding-bottom);
}
</style>
