// import { Api } from "@/service/api.service";
// import { AccountReferralCode, QueryMask } from "@kinherit/sdk";

// export interface ReadReferralCodeMessage {
//   query?: QueryMask<typeof AccountReferralCode>;
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadReferralCodeResponse {
//   referralCodes: Array<AccountReferralCode>;
// }

// export const ReadReferralCodeHandler = async (
//   message: ReadReferralCodeMessage,
// ): Promise<ReadReferralCodeResponse> => {
//   const request = Api.resource("core", "/v2/core/select/account-referral-code")
//     .method("get")

//     .paginate(message.pagination ?? false);

//   request.buildQuery(AccountReferralCode).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     referralCodes: AccountReferralCode.$inflate(
//       response.accountReferralCode,
//       undefined,
//       response.$rootIds,
//     ),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk";

export const ReadReferralCodeHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/account-referral-code",
  method: "get",
  rootModel: AccountReferralCode,
  parse: () => ({}),
});
