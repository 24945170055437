import { FormPasswordField } from "@kinherit/framework/component.input/password-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const ChangePasswordForm = () =>
  defineForm({
    name: "change-password-form",
    data: () => ({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    }),
    formAreas: (data) => [
      defineFormArea({
        name: `change-password-form-area`,
        data,
        template: GridLayout([
          ["oldPassword"],
          ["newPassword", "confirmNewPassword"],
        ]),
        components: () => ({
          oldPassword: [
            FormPasswordField({
              props: {
                label: "Old Password",
                message: "For security, please enter your current password",
                validators: ["required"],
                class: "old-password",
                reference: "oldPassword",
              },
              models: {
                value: "oldPassword",
              },
            }),
          ],
          newPassword: [
            FormPasswordField({
              props: {
                label: "New Password",
                validators: ["required"],
                message: "Password must be at least 8 characters long",
                class: "new-password",
                reference: "newPassword",
              },
              models: {
                value: "newPassword",
              },
            }),
          ],
          confirmNewPassword: [
            FormPasswordField({
              props: {
                label: "Confirm New Password",
                validators: ["required"],
                message: "Please retype your new password",
                reference: "confirmNewPassword",
              },
              models: {
                value: "confirmNewPassword",
              },
            }),
          ],
        }),
      }),
    ],
  });
