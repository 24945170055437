// import { Api } from "@/service/api.service";
// import { IntroducerContact, Profile, QueryMask } from "@kinherit/sdk";

// export interface ReadIntroducerContactMessage {
//   query?: QueryMask<typeof IntroducerContact>;
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadIntroducerContactResponse {
//   introducerContacts: Array<IntroducerContact>;
//   profiles: Array<Profile>;
// }

// export const ReadIntroducerContactHandler = async (
//   message: ReadIntroducerContactMessage,
// ): Promise<ReadIntroducerContactResponse> => {
//   const request = Api.resource("core", "/v2/core/select/introducer-contact")
//     .method("get")

//     .paginate(message.pagination ?? false);
//   request.buildQuery(IntroducerContact).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     introducerContacts: IntroducerContact.$inflate(
//       response.introducerContact,
//       undefined,
//       response.$rootIds,
//     ),
//     profiles: Profile.$inflate(response.profile),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk";

export const ReadIntroducerContactHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/introducer-contact",
  method: "get",
  rootModel: IntroducerContact,
  parse: () => ({}),
});
