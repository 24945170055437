// import { Equal, In } from "@kinherit/orm";
// import { Api, IOption, Option } from "@kinherit/sdk";

// export interface ReadOptionMessage {
//   group: string | Array<string>;
//   sort: {
//     by: keyof IOption;
//     direction: "asc" | "desc";
//   };
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadOptionResponse {
//   options: Array<Option>;
// }

// export const ReadOptionHandler = async (
//   message: ReadOptionMessage,
// ): Promise<ReadOptionResponse> => {
//   const request = Api.resource("core", "/v2/core/option")
//     .method("get")

//     .sort(message.sort)
//     .paginate(message.pagination);

//   request.buildQuery(Option).where({
//     group: Array.isArray(message.group)
//       ? In(message.group)
//       : Equal(message.group),
//   });

//   const result = await request.result();

//   return {
//     options: Option.$inflate(result.option, message.sort),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Equal, In } from "@kinherit/orm/index";
import { Option } from "@kinherit/sdk";

export const ReadOptionHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/option",
  method: "get",
  rootModel: Option,
  parse: (message: { group: string | string[] }) => ({
    query: {
      group: Array.isArray(message.group)
        ? In(message.group)
        : Equal(message.group),
    },
  }),
});
