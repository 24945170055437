import { Api } from "@/service/api.service";

interface InternalChangePasswordMessage {
  oldPassword: string;
  newPassword: string;
}

interface InternalChangePasswordResult {
  [key: string]: never;
}

export const ChangePasswordHandler = async (
  message: InternalChangePasswordMessage,
): Promise<InternalChangePasswordResult> => {
  await Api.resource("auth", "/v2/auth/change-password/kinvault", message)
    .method("put")
    .body({
      newPassword: message.newPassword,
      oldPassword: message.oldPassword,
    })
    .result();

  return {};
};
