import { Api } from "@/service/api.service";
import { ActiveSession } from "../../model/active-session.model";
import { Session } from "../../model/session.model";

interface CompleteMultiFactorAuthMessage {
  session: Session;
  code: number;
  multiFactorSessionId: string;
}

interface CompleteMultiFactorAuthResult {
  session: Session;
  activeSession: ActiveSession;
}

export const CompleteMultiFactorAuthHandler = async (
  message: CompleteMultiFactorAuthMessage,
): Promise<CompleteMultiFactorAuthResult> => {
  const session = message.session;

  const response = await Api.resource("auth", "/v2/auth/multi-factor/complete")
    .method("put")
    .body({
      code: message.code,
      session: message.multiFactorSessionId,
    })
    .result();

  const newSession = Session.$create({
    id: session.username,
    methods: [],
    mfaRequired: false,
    token: response.token,
    user: session.user,
    accountType: session.accountType,
  });

  newSession.$persist();

  const activeSession = ActiveSession.fromSession({
    ...newSession.$data,
  }).$persist();

  // await window.Kernel.ActionBus.execute("auth/session/complete", {
  //   activeSession,
  // });
  await window.Kernel.ActionBus.auth.session.complete();

  return { session: newSession, activeSession };
};
