import { PartnerPortalIndexBreadCrumb } from "@/module/dashboard/breadcrumbs";
import { RouteInterface } from "@kinherit/framework/core/route-config";
import { Routes as ClientRoutes } from "../../clients/page";

export const PartnerPortalIndexRoute = "PartnerPortalIndex";

export const Routes: Array<RouteInterface> = [
  {
    name: PartnerPortalIndexRoute,
    path: "/dashboard",
    component: () => import("./Index.vue"),
    meta: {
      breadcrumbs: PartnerPortalIndexBreadCrumb,
      title: `Dashboard`,
    },
    children: [...ClientRoutes],
  },
];
