<template>
  <div class="columns is-centered login-page">
    <div class="column is-6-tablet is-4-desktop">
      <Card title="Partner Login">
        <Form is-borderless :config="$data.form" @submit="handleClick" />
        <Button
          class="login-button mt-3"
          :is-fullwidth="true"
          color="is-positive"
          @click="handleClick"
          >Login</Button
        >
        <Button
          class="password-reset-button mt-3"
          :is-fullwidth="true"
          color="is-light-grey"
          @click="navigateToPasswordReset"
        >
          I forgot my password
        </Button>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { PartnerPortalIndexRoute } from "@/module/dashboard/page";
import { AuthService } from "@/service/auth.service";
import { Form } from "@kinherit/framework/component.form/form";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import {
  AuthForgotRoute,
  AuthLoginRoute,
  AuthRequestMfParams,
  AuthRequestMfRoute,
} from ".";
import { LoginForm } from "../form/login.form";

export default defineComponent({
  name: AuthLoginRoute,
  components: { Form, Button, Card },
  mixins: [
    AuthService.mixin({
      module: "logged-out",
    }),
  ],
  data: () => ({
    form: LoginForm(),
  }),
  methods: {
    navigateToPasswordReset() {
      window.Kernel.visitRoute({
        name: AuthForgotRoute,
      });
    },
    async handleClick(): Promise<void> {
      if (!this.form.isValid()) {
        this.form.options.showValidation = true;
        return;
      }

      // const { session } = await window.Kernel.ActionBus.execute(
      //   "auth/session/request",
      //   this.form.localData,
      // );
      const { session } = await window.Kernel.ActionBus.auth.session.request(
        this.form.localData,
      );

      if (session.mfaRequired) {
        const params: AuthRequestMfParams = {
          username: session.username,
        };

        window.Kernel.visitRoute({
          name: AuthRequestMfRoute,
          params,
          query: this.$route.query,
        });
      } else if (this.$route.query.redirect) {
        window.Kernel.visitRoute({
          path: this.$route.query.redirect as string,
          query: Object.omit(this.$route.query, ["redirect"]),
        });
      } else {
        window.Kernel.visitRoute({
          name: PartnerPortalIndexRoute,
        });
      }
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "LoginPageWrapper",
  "imports": {
    "LoginFormWrapper": "@/module/auth/form/login.form.test"
  },
  "route": "AuthLogin",
  "selector": ".login-page",
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LoginFormWrapper"
    },
    "login": {
      "type": "click",
      "selector": ".login-button"
    },
    "passwordPassword": {
      "type": "click",
      "selector": ".password-reset-button"
    }
  }
}
</cypress-wrapper>
