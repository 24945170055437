import { CreateInviteHandler } from "@/module/invites/action/create-invite.action";
import { DeleteInviteHandler } from "@/module/invites/action/delete-invite.action";
import { ReadInviteHandler } from "@/module/invites/action/read-invite.action";
import { SendInviteEmailHandler } from "@/module/invites/action/send-invite-email.action";
import { UpdateInviteHandler } from "@/module/invites/action/update-invite.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

// export const Actions = {
//   // invites
//   "partner-portal/invites/read": ReadInviteHandler,
//   "partner-portal/invites/create": CreateInviteHandler,
//   "partner-portal/invites/update": UpdateInviteHandler,
//   "partner-portal/invites/delete": DeleteInviteHandler,
//   "partner-portal/invites/send-invite-email": SendInviteEmailHandler,
// };

export const InvitesActionBus = DefineActionBus({
  name: "Invites",
  actions: {
    read: ReadInviteHandler,
    create: CreateInviteHandler,
    update: UpdateInviteHandler,
    delete: DeleteInviteHandler,
    sendInviteEmail: SendInviteEmailHandler,
  },
});
