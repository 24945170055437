import type { PartnerPortalAccountReferralDetailsParams } from "@/module/clients/page";
import { PartnerPortalIndexBreadCrumb } from "@/module/dashboard/breadcrumbs";

import { AccountReferral } from "@kinherit/sdk";

export const PartnerPortalAccountReferralMasterListBreadCrumb = () => [
  ...PartnerPortalIndexBreadCrumb(),
  {
    text: "Clients",
    route: { name: "PartnerPortalAccountReferralMasterList" },
  },
];

export const PartnerPortalAccountReferralFormBreadCrumb = () => [
  ...PartnerPortalAccountReferralMasterListBreadCrumb(),
  {
    text: "Refer a Client",
    route: { name: "PartnerPortalAccountReferralForm" },
  },
];

export const PartnerPortalAccountReferralDetailsBreadCrumb = (_params: any) => {
  const params = _params as PartnerPortalAccountReferralDetailsParams;

  const accountReferral = AccountReferral.$findOne(params.accountReferral);

  return [
    ...PartnerPortalAccountReferralMasterListBreadCrumb(),
    {
      text: accountReferral?.lead?.profile.fullName ?? "Client Details",
      route: { name: "PartnerPortalAccountReferralForm" },
    },
  ];
};
