import { OptionService } from "@/module/core/service/option.service";
import { ActiveSession } from "../../model/active-session.model";

interface CompleteSessionResponse {
  [key: string]: never;
}

export const CompleteSessionHandler =
  async (): Promise<CompleteSessionResponse> => {
    const activeSession = ActiveSession.$getInstance();

    if (!activeSession) {
      throw new Error("No active session found");
    }

    await Promise.allSettled([
      // window.Kernel.ActionBus.execute("auth/session/read", {}),
      window.Kernel.ActionBus.auth.session.read(),
      OptionService.loadOptions(),
    ]);

    window.Kernel.ComponentOptions.Navbar.callRefresh();
    return {};
  };
