// import { Api } from "@/service/api.service";
// import { Profile, QueryMask, User } from "@kinherit/sdk";

// export interface ReadUserMessage {
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
//   query?: QueryMask<typeof User>;
// }

// interface ReadUserResponse {
//   users: Array<User>;
//   profiles: Array<Profile>;
// }

// export const ReadUserHandler = async (
//   message: ReadUserMessage,
// ): Promise<ReadUserResponse> => {
//   const request = Api.resource("core", "/v2/core/select/user")
//     .method("get")

//     .paginate(message.pagination ?? false);
//   request.buildQuery(User).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     users: User.$inflate(response.user, undefined, response.$rootIds),
//     profiles: Profile.$inflate(response.profile),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { User } from "@kinherit/sdk";

export const ReadUserHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/user",
  method: "get",
  rootModel: User,
  parse: () => ({}),
});
