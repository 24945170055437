<template>
  <div class="columns is-centered forgot-page">
    <div class="column is-6-tablet is-4-desktop">
      <Card title="Reset Password">
        <Form is-borderless :config="$data.form" @submit="handleClick" />
        <Button
          class="reset-password-button mt-3"
          :is-fullwidth="true"
          color="is-primary"
          @click="handleClick"
        >
          Reset Password
        </Button>
        <Button
          :is-fullwidth="true"
          class="return-to-login-button mt-3"
          color="is-light-grey"
          @click="navigateToLogin"
        >
          Return to Login
        </Button>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { Form } from "@kinherit/framework/component.form/form";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { defineComponent } from "vue";
import { AuthForgotRoute, AuthLoginRoute } from ".";
import { ForgotForm } from "../form/forgot.form";

export default defineComponent({
  name: AuthForgotRoute,
  components: { Form, Button, Card },
  mixins: [
    AuthService.mixin({
      module: "logged-out",
    }),
  ],
  data: () => ({
    form: ForgotForm(),
  }),
  methods: {
    navigateToLogin() {
      window.Kernel.visitRoute({
        name: AuthLoginRoute,
      });
    },
    async handleClick(): Promise<void> {
      if (!this.form.isValid()) {
        this.form.options.showValidation = true;
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "auth/reset-password",
      //   this.form.localData,
      // );
      await window.Kernel.ActionBus.auth.changePassword.reset(
        this.form.localData,
      );

      await OpenAlertDialog({
        dialog: {
          title: "Reset Password",
          message: `If this account exists, an email has been sent to reset its password.`,
        },
        button: {
          cancel: {
            show: false,
          },
        },
      });

      window.Kernel.visitRoute({
        name: AuthLoginRoute,
      });
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "ForgotPageWrapper",
  "imports": {
    "ForgotFormWrapper": "@/module/auth/form/forgot.form.test"
  },
  "route": "AuthForgotRoute",
  "selector": ".forgot-page",
  "methods": {
    "resetPassword": {
      "type": "click",
      "selector": ".reset-password-button"
    },
    "returnToLogin": {
      "type": "click",
      "selector": ".return-to-login-button"
    },
    "form": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ForgotFormWrapper"
    }
  }
}
</cypress-wrapper>
