import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { In, Like } from "@kinherit/orm";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/index";

// export const ReadInviteHandler = async (message: {
//   name: string;
//   email: string;
//   status: (
//     | "notsent"
//     | "sent"
//     | "complete"
//     | "failed"
//     | "declined"
//     | "cancelled"
//   )[];
//   sort: {
//     by: keyof IBrandedKinvaultInvitation;
//     direction: "desc" | "asc";
//   };
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }) => {
//   const company = AuthService.activeIntroducerCompany;

//   if (!company) {
//     throw new Error("No active company");
//   }

//   const request = Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/branded-kinvault-invitation",
//   )
//     .method("get")
//     .sort(message.sort)
//     .paginate(message.pagination);

//   request.buildQuery(BrandedKinvaultInvitation).where({
//     profile: {
//       fullName: Like(message.name),
//       emails: {
//         email: Like(message.email),
//       },
//     },
//     status: In(message.status),
//   });

//   const response = await request.result();

//   return {
//     profile: Profile.$inflate(response.profile),
//     emailAddress: EmailAddress.$inflate(response.emailAddress),
//     brandedKinvaultInvitation: BrandedKinvaultInvitation.$inflate(
//       response.brandedKinvaultInvitation,
//     ),
//     accountReferralCode: AccountReferralCode.$inflate(
//       response.accountReferralCode,
//     ),
//     brandedKinvaultInviteReferral: BrandedKinvaultInviteReferral.$inflate(
//       response.brandedKinvaultInviteReferral,
//     ),
//     brandedKinvault: BrandedKinvault.$inflate(response.brandedKinvault),
//     pagination: getPagination(response),
//   };
// };

export const ReadInviteHandler = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/branded-kinvault-invitation",
  method: "get",
  rootModel: BrandedKinvaultInvitation,
  parse: (message: {
    name: string;
    email: string;
    status: (
      | "notsent"
      | "sent"
      | "complete"
      | "failed"
      | "declined"
      | "cancelled"
    )[];
  }) => ({
    query: {
      profile: {
        fullName: Like(message.name),
        emails: {
          email: Like(message.email),
        },
      },
      status: In(message.status),
    },
  }),
});
