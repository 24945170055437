// import { Api } from "@/service/api.service";
// import { KnowledgeBaseCategory, QueryMask } from "@kinherit/sdk";

// export interface ReadKnowledgeBaseCategoryMessage {
//   query?: QueryMask<typeof KnowledgeBaseCategory>;
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadKnowledgeBaseCategoryResponse {
//   knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
// }

// export const ReadKnowledgeBaseCategoryHandler = async (
//   message: ReadKnowledgeBaseCategoryMessage,
// ): Promise<ReadKnowledgeBaseCategoryResponse> => {
//   const request = Api.resource(
//     "core",
//     "/v2/core/select/knowledge-base-category",
//   )
//     .method("get")

//     .paginate(message.pagination ?? false);
//   request.buildQuery(KnowledgeBaseCategory).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
//       response.knowledgeBaseCategory,
//       undefined,
//       response.$rootIds,
//     ),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

export const ReadKnowledgeBaseCategoryHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/knowledge-base-category",
  method: "get",
  rootModel: KnowledgeBaseCategory,
  parse: () => ({}),
});
