import {
  OptionGroup,
  OptionService,
} from "@/module/core/service/option.service";
import { ComponentModelType } from "@kinherit/framework/component.config/component-helpers";
import {
  AutoCompleteField,
  FormAutoCompleteField,
} from "@kinherit/framework/component.input/auto-complete-field";
import {
  DateRangeField,
  FormDateRangeField,
} from "@kinherit/framework/component.input/date-range-field";
import {
  FormSelectField,
  SelectField,
} from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { FormBuilderComponentOptions } from "@kinherit/framework/form-builder/core/component-wrapper";
import { DashLoader } from "@kinherit/framework/service/dash-loader";
import {
  Dictionary,
  DictionaryName,
} from "@kinherit/framework/service/dictionary";
import { Equal, In, Like, Model, NotEqual, NotIn } from "@kinherit/orm";
import {
  AccountReferralCode,
  Address,
  BrandedKinvault,
  EmailAddress,
  EmailTemplate,
  IntroducerCompany,
  IntroducerContact,
  IntroducerContract,
  Kintin,
  KnowledgeBaseCategory,
  Option,
  Person,
  Product,
  QueryMask,
  Role,
  Tag,
  User,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";

export const SharedFormProps = {
  size: "is-normal" as const,
  isScrollable: true,
};

export const SharedFilterProps = {
  size: "is-small" as const,
  isScrollable: true,
};

const SharedOptionFieldProps: Record<
  OptionGroup,
  ReturnType<typeof FormAutoCompleteField>["props"]
> = {
  process: {
    label: "Process",
  },
  relationRelationship: {
    label: "Relationship",
  },
  genders: {
    label: "Genders",
  },
  maritalStatuses: {
    label: "Marital Statuses",
  },
  fileLocations: {
    label: "File Location",
  },
  businessAssetTypes: {
    label: "Asset Type",
  },
  businessTypes: {
    label: "Business Type",
  },
  ownedTypes: {
    label: "Who does this apply to?",
  },
  cashDebtTypes: {
    label: "Cash/Debt Type",
  },
  investmentTypes: {
    label: "Investment Type",
  },
  otherAssetTypes: {
    label: "Other Asset Type",
  },
  pensionTypes: {
    label: "Pension Type",
  },
  policyTypes: {
    label: "Policy Type",
  },
  ownershipTypes: {
    label: "Ownership Type",
  },
  policyPayOutTypes: {
    label: "Policy Pay Out Type",
  },
  propertyTypes: {
    label: "Property Type",
  },
  howOwnedTypes: {
    label: "How Owned Type",
  },
  titles: {
    label: "Title",
  },
  locationTypes: {
    label: "Location",
  },
  lifeTimeGiftTypes: {
    label: "Lifetime Gift Type",
  },
  fileTypes: {
    label: "File Type",
  },
  kintinTypes: {
    label: "Kintin Type",
  },
  kintinStatus: {
    label: "Kintin Status",
  },
  kintinStage: {
    label: "Kintin Stage",
  },
  leadStatus: {
    label: "Lead Status",
  },
};

export const OptionsAutoCompleteField = <Data>({
  vModel,
  group,
  props = {},
  simplifyData = false,
}: {
  vModel: string | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  group: OptionGroup;
  props?: ReturnType<typeof FormAutoCompleteField<Data>>["props"];
  simplifyData?: boolean;
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  const options = OptionService.getOptions(group, true);

  return FormAutoCompleteField({
    models: {
      value:
        "string" === typeof vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(data, vModel.split("."));
                return simplifyData
                  ? Array.isArray(value) && value.length > 0
                    ? options.filter((o) => value.includes(o.id))
                    : options.find((o) => o.id === value)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(data, vModel.split("."), option);
              },
            }
          : vModel,
    },
    props: {
      placeholder: "Any",
      ...SharedOptionFieldProps[group],
      ...props,
      options: options,
      mapOptions: {
        label: "text",
        value: "id",
        line1: "description",
      },
      reference: "string" === typeof vModel ? vModel : props.reference,
    },
  });
};

export const OptionsSelectField = <Data>({
  reactive,
  vModel,
  filter,
  group,
  props = {},
  simplifyData = false,
}: {
  reactive?: boolean;
  vModel: string | ComponentModelType<typeof SelectField, Data>["value"];
  group: OptionGroup;
  filter?: (option: Option) => boolean;
  props?: ReturnType<typeof FormSelectField<Data>>["props"];
  simplifyData?: boolean;
}): ReturnType<typeof FormSelectField<Data>> => {
  let options = OptionService.getOptions(group, true);

  if (filter) {
    options = options.filter(filter);
  }

  return FormSelectField<Data>({
    reactive,
    models: {
      value:
        "string" === typeof vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(data, vModel.split("."));
                return simplifyData ? value.id : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? options.find((o) => o.id === value)
                  : value;
                DashLoader.set(data, vModel.split("."), option);
              },
            }
          : vModel,
    },
    props: {
      placeholder: "Any",
      ...SharedOptionFieldProps[group],
      ...props,
      options: options,
      mapOptions: {
        label: "text",
        value: "id",
      },
      reference: "string" === typeof vModel ? vModel : props.reference,
    },
  });
};

export const AccountNumberField = <Data>({
  props,
}: {
  props: ReturnType<typeof FormTextField<Data>>["props"] & { vModel: string };
}): ReturnType<typeof FormTextField<Data>> =>
  FormTextField({
    models: {
      value: props.vModel,
    },
    props: {
      label: "Account Number",
      placeholder: "i.e. 88889999",
      ...props,
      reference: props.vModel,
    },
  });

export const SortCodeField = <Data>({
  props,
}: {
  props: ReturnType<typeof FormTextField<Data>>["props"] & { vModel: string };
}): ReturnType<typeof FormTextField<Data>> =>
  FormTextField({
    models: {
      value: props.vModel,
    },
    props: {
      label: "Sort Code",
      placeholder: "i.e. 88-88-99",
      ...props,
      reference: props.vModel,
    },
  });

export const IBANField = <Data>({
  props,
}: {
  props: ReturnType<typeof FormTextField<Data>>["props"] & { vModel: string };
}): ReturnType<typeof FormTextField<Data>> =>
  FormTextField<Data>({
    models: {
      value: props.vModel,
    },
    props: {
      label: "IBAN",
      placeholder: "i.e. GB-XXXX-XXXX",
      ...props,
      reference: props.vModel,
    },
  });

export const DictionaryField = <Data>({
  dictionary,
  props,
}: {
  dictionary: DictionaryName;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel: string;
  };
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  const options = Object.entries(
    Dictionary.map([dictionary])[dictionary](),
  ).map(([key, value]) => ({
    value: key,
    text: value,
  }));

  return FormAutoCompleteField<Data>({
    models: {
      value: {
        get: (data) => {
          const value = DashLoader.get(data, props.vModel.split("."));

          return Array.isArray(value)
            ? options.filter((option) => value.includes(option.value))
            : options.find((option) => value === option.value);
        },
        set: (option, data) => {
          DashLoader.set(data, props.vModel.split("."), option?.value || null);
        },
      },
    },
    props: {
      placeholder: "Any",
      ...props,
      options,
      mapOptions: {
        value: "value",
        label: "text",
      },
      reference: props.vModel,
    },
  });
};

export const RoleField = <Data>({
  props,
  simplifyData = false,
}: {
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  const options =
    window.Kernel.Mode === KernelModes.Dev
      ? Role.$all().sortBy("role")
      : Role.$findBy({
          role: NotEqual("admin"),
        }).sortBy("role");
  return FormAutoCompleteField<Data>({
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );
                return simplifyData
                  ? Array.isArray(value)
                    ? options.filter((option) => value.includes(option.id))
                    : options.find((option) => value === option.id)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      label: "Role",
      ...props,
      options,
      mapOptions: {
        value: "id",
        label: (role: Role) => {
          return role.role.ucFirst();
        },
      },
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
  });
};

export const CreatedAtField = <Data>({
  props,
  simplifyData = false,
}: {
  props: ReturnType<typeof FormDateRangeField<Data>>["props"] & {
    vModel: string | ComponentModelType<typeof DateRangeField, Data>["value"];
  };
  simplifyData?: boolean;
}): ReturnType<typeof FormDateRangeField<Data>> => {
  return FormDateRangeField<Data>({
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );
                return simplifyData
                  ? value
                    ? [
                        new DateTime(Number.parseInt(value[0].toString())),
                        new DateTime(Number.parseInt(value[1].toString())),
                      ]
                    : undefined
                  : null;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? value
                    ? [value[0].timestamp, value[1].timestamp]
                    : undefined
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      placeholder: "Any",
      label: "Created",
      ...props,
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
  });
};

export const TagsField = <Data>({
  props,
  simplifyData = false,
}: {
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  const options = Tag.$all().sortBy("name");

  return FormAutoCompleteField<Data>({
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );
                return simplifyData
                  ? Array.isArray(value)
                    ? options.filter((option) => value.includes(option.id))
                    : options.find((option) => value === option.id)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      label: "Tags",

      ...props,
      options,
      mapOptions: {
        value: "id",
        label: "name",
      },
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
  });
};

// Asynchronous fields

const GenericModelField = <Data, Entity extends new (data: any) => Model<any>>({
  reactive = false,
  selectField = false,
  model,
  props,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  asyncLoad,
  asyncFilter,
  slots,
}: {
  reactive?: boolean;
  selectField?: boolean;
  model: Entity;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
  optionTitleField: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | undefined);
  optionLine2Field?: string | ((item: any) => string | undefined);
  asyncLoad?: (options: any[]) => Promise<any[]>;
  asyncFilter?: (search?: string | null) => Promise<any[]>;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return (selectField ? FormSelectField : FormAutoCompleteField)({
    reactive: reactive,
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );

                if (Array.isArray(value) && value.length === 0) {
                  return [];
                }

                return simplifyData
                  ? Array.isArray(value)
                    ? (model as any).$findBy({
                        id: In(value),
                      })
                    : (model as any).$findOne(value)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      label: model.name.toSentenceCase(),
      ...props,
      options: props.options ?? [],
      asyncLoad: asyncLoad ? () => asyncLoad : undefined,
      asyncFilter:
        (props.options?.length ?? 0) > 0 ? undefined : () => asyncFilter,
      mapOptions: {
        value: "id",
        line1: (optionLine1Field ? optionLine1Field : undefined) as never,
        label: optionTitleField,
        line2: (optionLine2Field ? optionLine2Field : undefined) as never,
      },
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
    slots,
  });
};

export const EmailAddressField = <Data>({
  props,
  simplifyData = false,
  options,
  slots,
  query,
  limit = 20,
}: {
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
  limit?: number;
} & (
  | {
      options: EmailAddress[];
      query?: undefined;
    }
  | {
      query: QueryMask<typeof EmailAddress>;
      options?: undefined;
    }
)): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    slots,
    model: EmailAddress,
    simplifyData,
    optionTitleField: (emailAddress: EmailAddress) => {
      let title = ``;

      if (emailAddress.profile?.fullName) {
        title += emailAddress.profile.fullName;
      } else if (emailAddress.profile?.organisationName) {
        title += emailAddress.profile.organisationName;
      }

      return `` !== title
        ? `${title} (${emailAddress.email})`
        : emailAddress.email;
    },
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { emailAddress } =
        await window.Kernel.ActionBus.core.select.emailAddress(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return emailAddress;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        query = {
          ...query,
          profile: {
            ...((query as any).profile ?? {}),
            fullName: Like(search),
          },
        };
      }

      const { emailAddress: options } =
        await window.Kernel.ActionBus.core.select.emailAddress(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
    props: {
      ...props,
      options: options,
    },
  });
};

export const AddressField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Address>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    model: Address,
    props: {
      label: "Address",
      ...props,
    },
    simplifyData,
    optionTitleField: "summary",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { address } = await window.Kernel.ActionBus.core.select.address(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return address;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, { summary: Like(search as string) });
      }

      const { address: options } =
        await window.Kernel.ActionBus.core.select.address(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

//export const AdviserField = <Data>({
//  props,
//  query,
//  simplifyData = false,
//  slots,
//  limit = 20,
//}: {
//  limit?: number;
//  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
//    vModel:
//      | string
//      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
//  };
//  query?: QueryMask<typeof Adviser>;
//  simplifyData?: boolean;
//  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
//}): ReturnType<
//  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
//> =>
//  GenericModelField({
//    slots,
//    props,
//    simplifyData,
//    model: Adviser,
//    optionTitleField: "person.profile.fullName",
//    asyncFilter: async (search: string | null | undefined) => {
//      if (!query) {
//        query = {};
//      }
//
//      if (![null, "", undefined].includes(search)) {
//        Object.assign(query, {
//          person: {
//            profile: {
//              fullName: Like(search as string),
//              ...(query.person?.profile ?? {}),
//            },
//            ...(query.person ?? {}),
//          },
//        });
//      }
//      const { advisers: options } = await window.Kernel.ActionBus.execute(
//        "core/select/adviser/read",
//        {
//          query,
//          pagination: {
//            currentPage: 0,
//            perPage: limit,
//          },
//        },
//        {
//          hideLoading: true,
//        },
//      );
//      return options;
//    },
//  });

export const BrandedKinvaultField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof BrandedKinvault>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: BrandedKinvault,
    optionTitleField: "profile.organisationName",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { brandedKinvault } =
        await window.Kernel.ActionBus.core.select.brandedKinvault(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return brandedKinvault;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: { organisationName: Like(search as string) },
        });
      }

      const { brandedKinvault: options } =
        await window.Kernel.ActionBus.core.select.brandedKinvault(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const ReferralCodeField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof AccountReferralCode>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: AccountReferralCode,
    optionTitleField: "code",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { accountReferralCode } =
        await window.Kernel.ActionBus.core.select.referralCode(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return accountReferralCode;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          code: Like(search as string),
        });
      }

      const { accountReferralCode: options } =
        await window.Kernel.ActionBus.core.select.referralCode(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const IntroducerContractField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerContract>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: IntroducerContract,
    optionTitleField: "name",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { introducerContract } =
        await window.Kernel.ActionBus.core.select.introducerContract(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerContract;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      }

      const { introducerContract: options } =
        await window.Kernel.ActionBus.core.select.introducerContract(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const KnowledgeBaseCategoryField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof KnowledgeBaseCategory>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: KnowledgeBaseCategory,
    optionTitleField: "title",
    optionLine1Field: "slug",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { knowledgeBaseCategory } =
        await window.Kernel.ActionBus.core.select.knowledgeBaseCategory(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return knowledgeBaseCategory;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      }

      const { knowledgeBaseCategory: options } =
        await window.Kernel.ActionBus.core.select.knowledgeBaseCategory(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const IntroducerContactField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerContact>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: IntroducerContact,
    optionTitleField: "profile.fullName",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { introducerContact } =
        await window.Kernel.ActionBus.core.select.introducerContact(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerContact;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: {
            fullName: Like(search as string),
          },
        });
      }

      const { introducerContact: options } =
        await window.Kernel.ActionBus.core.select.introducerContact(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const IntroducerCompanyField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerCompany>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: IntroducerCompany,
    optionTitleField: "profile.fullName",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { introducerCompany } =
        await window.Kernel.ActionBus.core.select.introducerCompany(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerCompany;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: {
            fullName: Like(search as string),
          },
        });
      }

      const { introducerCompany: options } =
        await window.Kernel.ActionBus.core.select.introducerCompany(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const ProductField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Product>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: Product,
    optionTitleField: (item: Product) =>
      `${item.publicText} (${item.price.format})`,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { product } = await window.Kernel.ActionBus.core.select.product(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return product;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          publicText: Like(search as string),
        });
      }

      const { product: options } =
        await window.Kernel.ActionBus.core.select.product(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const KintinField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Kintin>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: Kintin,
    optionTitleField: "friendlyName",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { kintin } = await window.Kernel.ActionBus.core.select.kintin(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return kintin;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          friendlyName: Like(search as string),
        });
      }

      const { kintin: options } =
        await window.Kernel.ActionBus.core.select.kintin(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const PersonCompanyField = <Data>({
  props,
  query = {},
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Person>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: Person,
    optionTitleField: "profile.fullName",
    optionLine1Field: "profile.organisationName",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { person } = await window.Kernel.ActionBus.core.select.person(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return person;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: { fullName: Like(search as string) },
        });
      }

      Object.assign(query, {
        type: Equal("company"),
      });

      const { person: options } =
        await window.Kernel.ActionBus.core.select.person(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const PersonField = <Data>({
  props,
  query = {},
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Person>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: Person,
    optionTitleField: "profile.fullName",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { person } = await window.Kernel.ActionBus.core.select.person(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return person;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: { fullName: Like(search as string) },
        });
      }

      const { person: options } =
        await window.Kernel.ActionBus.core.select.person(
          {
            query,
            pagination: {
              currentPage: 0,
              perPage: limit,
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const KintinAddressField = <Data>({
  selectField,
  props,
  kintin,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
  exclude,
  unique,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  kintin: string;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | undefined);
  optionLine2Field?: string | ((item: any) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
  exclude?: Address[];
  unique?: boolean;
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: Address,
    optionTitleField: optionTitleField ?? "summary",
    optionLine1Field: optionLine1Field
      ? optionLine1Field
      : !unique
        ? "profile.fullName"
        : undefined,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { address } =
        await window.Kernel.ActionBus.core.select.kintinAddress(
          {
            query: {
              id: In(options),
            },
            message: {
              kintin,
            },
          },
          {
            hideLoading: true,
          },
        );

      return address;
    },
    asyncFilter: async (search?: string | null) => {
      const { address: options } =
        await window.Kernel.ActionBus.core.select.kintinAddress(
          {
            query: {
              summary: Like(search as string),
              id: NotIn(exclude?.pluck("id")),
            },
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
            message: {
              kintin,
            },
          },
          {
            hideLoading: true,
          },
        );

      if (true === unique) {
        return options.unique("summary");
      }

      return options;
    },
  });

export const UserField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof User>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: User,
    optionTitleField: "profile.fullName",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { user } = await window.Kernel.ActionBus.core.select.user(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return user;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: { fullName: Like(search as string) },
        });
      }

      const { user: options } = await window.Kernel.ActionBus.core.select.user(
        {
          query,
          pagination: {
            currentPage: 0,
            perPage: limit,
          },
        },
        {
          hideLoading: true,
        },
      );

      return options;
    },
  });

export const EmailTemplateField = <Data>({
  props,
  query,
  simplifyData = false,
  slots,
  limit = 20,
}: {
  limit?: number | null;
  props: ReturnType<typeof FormAutoCompleteField<Data>>["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof EmailTemplate>;
  simplifyData?: boolean;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    slots,
    props,
    simplifyData,
    model: EmailTemplate,
    optionTitleField: "name",
    optionLine1Field: (item: EmailTemplate) =>
      [
        item.$data.allowAttachments ? "✓ Attachments" : null,
        item.$data.strictAttachments ? "✓ Strict" : null,
      ]
        .filter(Boolean)
        .join(", ") || undefined,
    optionLine2Field: (item: EmailTemplate) =>
      [
        item.$data.preloadedFiles.length > 0
          ? `${item.$data.preloadedFiles.length} Preloaded Files`
          : null,
        item.$data.requiredAttachments.length > 0
          ? `${item.$data.requiredAttachments.length} Required Attachments`
          : null,
      ]
        .filter(Boolean)
        .join(", ") || undefined,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      const { emailTemplate } =
        await window.Kernel.ActionBus.core.select.emailTemplate(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return emailTemplate;
    },
    asyncFilter: async (search: string | null | undefined) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      }

      const { emailTemplate: options } =
        await window.Kernel.ActionBus.core.select.emailTemplate(
          {
            query,
            pagination:
              null === limit
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const BelongsToField = <
  Data,
  Component extends FormBuilderComponentOptions<
    typeof SelectField,
    Data
  > = FormBuilderComponentOptions<typeof SelectField, Data>,
>({
  kintin,
  props,
  slots,
  models,
}: {
  kintin: Kintin;
  props?: ReturnType<typeof FormSelectField<Data>>["props"];
  slots?: Component["slots"];
  models?: Component["models"];
}): ReturnType<typeof FormSelectField<Data>> => {
  const primaryProfile = kintin.primaryPerson?.profile;
  const secondaryProfile = kintin.secondaryPerson?.profile;

  return FormSelectField({
    props: {
      placeholder: "-- Please select an option --",
      ...props,
      label: "Belongs To",
      options: OptionService.getOptions("ownedTypes", true)
        .sort((a, b) => {
          if (a.value === "primary") {
            return -1;
          }

          if (b.value === "secondary") {
            return 0;
          }

          if (b.value === "both") {
            return 1;
          }

          return -1;
        })
        .filter((x) => {
          if (undefined === secondaryProfile && x.value !== "primary") {
            return false;
          }

          return true;
        }),
      mapOptions: {
        label: (item: Option) => {
          switch (item.value) {
            case "primary":
              return primaryProfile.fullName as string;
            case "secondary":
              return secondaryProfile?.fullName as string;
            case "both":
              return "Both of us";
            default:
              return item.text;
          }
        },
        value: "id",
      },
    },
    slots,
    models,
  });
};
