import { Api } from "@/service/api.service";
import { MfAuthMethod } from "@kinherit/sdk";

interface ResendMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface ResendMfAuthMethodResponse {}

export const ResendMfAuthMethodHandler = async (
  message: ResendMfAuthMethodMessage,
): Promise<ResendMfAuthMethodResponse> => {
  await Api.resource(
    "kinvault",
    "/v2/kinvault/mf-auth-method/{mfAuthMethod}/resend",
    {
      mfAuthMethod: message.mfAuthMethod,
    },
  )
    .method("put")

    .result();

  return {};
};
