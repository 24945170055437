import { FooterOptionsConfig } from "@kinherit/framework/component.layout/footer/types";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";

export const DefaultFooterConfig: ReturnType<FooterOptionsConfig> = {
  elements: {
    // container: {
    //   style: {
    //     background: "#333",
    //   },
    //   class: ["is-dark"],
    // },
  },
  content: {
    type: "kinvault",
    clientText:
      "<p>Kinherit LTD, Bradbrooke House, Almondsbury Business Centre, Woodlands, Bradley Stoke, Bristol, BS32 4QH United Kingdom. Registered in the UK, Company number 11500962</p>",
    links: [
      {
        href: "https://www.facebook.com/kinherit",
        icon: ThemeIconName.Facebook,
        title: "Find us on Facebook",
      },
      {
        href: "https://twitter.com/kinheritltd",
        icon: ThemeIconName.Twitter,
        title: "Find us on Twitter",
      },
      {
        href: "https://www.linkedin.com/company/kinherit-ltd/",
        icon: ThemeIconName.Linkedin,
        title: "Find us on Linkedin",
      },
    ],
  },
};

export const FooterConfig: FooterOptionsConfig = () => DefaultFooterConfig;
