// import { Api } from "@/service/api.service";
// import {
//   BrandedKinvault,
//   IBrandedKinvault,
//   Profile,
//   User,
// } from "@kinherit/sdk";

// export interface ReadBrandedKinvaultMembershipMessage {
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
//   sort?: {
//     by: keyof IBrandedKinvault;
//     direction: "asc" | "desc";
//   };
// }

// interface ReadBrandedKinvaultMembershipResponse {
//   brandedKinvaultMemeberships: Array<BrandedKinvault>;
//   users: Array<User>;
//   profiles: Array<Profile>;
// }

// export const ReadBrandedKinvaultMembershipHandler = async (
//   message: ReadBrandedKinvaultMembershipMessage,
// ): Promise<ReadBrandedKinvaultMembershipResponse> => {
//   const response = await Api.resource(
//     "core",
//     "/v2/core/select/branded-kinvault-membership",
//   )
//     .method("get")

//     .sort({
//       sortBy: message.sort?.by ?? "user.profile.fullName",
//       sortDirection: message.sort?.direction ?? "asc",
//     })
//     .paginate(message.pagination ?? false)
//     .result();

//   return {
//     brandedKinvaultMemeberships: BrandedKinvault.$inflate(
//       response.brandedKinvaultMembership,
//       message.sort,
//       response.$rootIds,
//     ),
//     users: User.$inflate(response.user),
//     profiles: Profile.$inflate(response.profile),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvault } from "@kinherit/sdk";

export const ReadBrandedKinvaultMembershipHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/branded-kinvault-membership",
  method: "get",
  rootModel: BrandedKinvault,
  parse: () => ({}),
});
