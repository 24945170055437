import { Api } from "@/service/api.service";
import { MfAuthMethod } from "@kinherit/sdk";

interface UpdateMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface UpdateMfAuthMethodResponse {
  mfAuthMethod: MfAuthMethod;
}

export const UpdateMfAuthMethodHandler = async (
  message: UpdateMfAuthMethodMessage,
): Promise<UpdateMfAuthMethodResponse> => {
  const respense = await Api.resource(
    "kinvault",
    "/v2/kinvault/mf-auth-method/{mfAuthMethod}",
    message,
  )
    .method("patch")
    .body({
      type: message.mfAuthMethod.$data.type,
      alias: message.mfAuthMethod.$data.alias,
      device: message.mfAuthMethod.$data.device,
      key: message.mfAuthMethod.$data.key,
      credential: message.mfAuthMethod.$data.credential,
      setupCompleteAt: message.mfAuthMethod.$data.setupCompleteAt,
    })
    .result();

  const mfAuthMethod = MfAuthMethod.$inflate(respense.mfAuthMethod).first();

  if (!mfAuthMethod) {
    throw new Error("Failed to update auth method");
  }

  message.mfAuthMethod.$delete();

  return {
    mfAuthMethod,
  };
};
