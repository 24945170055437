export const SettingsBreadCrumb = () => [
  {
    text: "Settings",
    route: { name: "SettingsIndex" },
  },
];

export const ChangePasswordBreadCrumb = () => [
  ...SettingsBreadCrumb(),
  {
    text: "Change Password",
    route: { name: "SettingsChangePassword" },
  },
];

export const MfAuthBreadCrumb = () => [
  ...SettingsBreadCrumb(),
  {
    text: "MF Auth",
    route: { name: "SettingsMfAuthMethods" },
  },
];
