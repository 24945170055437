import { Option } from "@kinherit/sdk";

export type OptionGroup =
  | "fileLocations"
  | "businessAssetTypes"
  | "businessTypes"
  | "ownedTypes"
  | "cashDebtTypes"
  | "investmentTypes"
  | "otherAssetTypes"
  | "pensionTypes"
  | "policyTypes"
  | "ownershipTypes"
  | "policyPayOutTypes"
  | "howOwnedTypes"
  | "propertyTypes"
  | "titles"
  | "locationTypes"
  | "lifeTimeGiftTypes"
  | "fileTypes"
  | "kintinTypes"
  | "genders"
  | "maritalStatuses"
  | "relationRelationship"
  | "leadStatus"
  | "kintinStatus"
  | "kintinStage"
  | "process";

export class OptionService {
  private static groups: Array<OptionGroup> = [
    "fileLocations",
    "businessAssetTypes",
    "businessTypes",
    "ownedTypes",
    "cashDebtTypes",
    "investmentTypes",
    "otherAssetTypes",
    "pensionTypes",
    "policyTypes",
    "ownershipTypes",
    "policyPayOutTypes",
    "howOwnedTypes",
    "propertyTypes",
    "titles",
    "locationTypes",
    "lifeTimeGiftTypes",
    "fileTypes",
    "kintinTypes",
    "genders",
    "maritalStatuses",
    "relationRelationship",
    "leadStatus",
    "kintinStatus",
    "kintinStage",
    "process",
  ];

  public static async loadOptions(): Promise<void> {
    await Promise.all(
      OptionService.groups
        .chunk(Math.ceil(OptionService.groups.length / 1))
        .map((groups) =>
          // window.Kernel.ActionBus.execute("core/option/read", {
          //   group: groups,
          //   sort: {
          //     by: "text",
          //     direction: "asc",
          //   },
          // }),
          window.Kernel.ActionBus.core.options.read({
            message: {
              group: groups,
            },
            sort: {
              by: "text",
              direction: "asc",
            },
          }),
        ),
    );
  }

  public static getOptions(group: OptionGroup, asArray: true): Option[];
  public static getOptions(
    group: OptionGroup,
    asArray: false,
  ): Record<string, string>;
  public static getOptions(
    group: OptionGroup,
    asArray = true,
  ): Option[] | Record<string, string> {
    const options = Option.$findBy({
      group,
    }).sortBy("text");

    if (asArray) {
      return options;
    }

    const optionsObject: Record<string, string> = {};

    options.forEach((option) => {
      optionsObject[option.id] = option.text;
    });

    return optionsObject;
  }
}
