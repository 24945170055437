// import { Api } from "@/service/api.service";
// import { ITag, Tag } from "@kinherit/sdk";

// export interface ReadTagMessage {
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
//   sort?: {
//     by: keyof ITag;
//     direction: "asc" | "desc";
//   };
// }

// interface ReadTagResponse {
//   tags: Array<Tag>;
// }

// export const ReadTagHandler = async (
//   message: ReadTagMessage,
// ): Promise<ReadTagResponse> => {
//   const response = await Api.resource("core", "/v2/core/select/tag")
//     .method("get")

//     .sort({
//       sortBy: message.sort?.by ?? "name",
//       sortDirection: message.sort?.direction ?? "asc",
//     })
//     .paginate(message.pagination ?? false)
//     .result();

//   return {
//     tags: Tag.$inflate(response.tag, message.sort, response.$rootIds),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Tag } from "@kinherit/sdk";

export const ReadTagHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/tag",
  method: "get",
  rootModel: Tag,
  parse: () => ({}),
});
