// import { AuthService } from "@/service/auth.service";
// import { getPagination } from "@kinherit/framework/action-bus/helper";
// import { Like } from "@kinherit/orm";
// import {
//   AccountReferral,
//   AccountReferralCode,
//   Api,
//   IAccountReferral,
//   IntroducerContact,
//   Kintin,
//   Lead,
//   Profile,
// } from "@kinherit/sdk";

// export const ReadAccountReferralHandler = async (message: {
//   name: string;
//   // ref: string;
//   email: string;
//   phoneNumber: string;
//   partnerPortalGroup: string;
//   sort: {
//     by: keyof IAccountReferral;
//     direction: "desc" | "asc";
//   };
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }) => {
//   const company = AuthService.activeIntroducerCompany;

//   if (!company) {
//     throw new Error("No active company");
//   }

//   const request = Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/account-referral",
//   )
//     .method("get")
//     .params({
//       partnerPortalGroup: message.partnerPortalGroup,
//       introducerCompany: company.id,
//     })
//     .sort(message.sort)
//     .paginate(message.pagination);

//   request.buildQuery(AccountReferral).where({
//     kintin: {
//       // ref: Like(message.ref),
//       friendlyName: Like(message.name),
//       primaryPerson: {
//         profile: {
//           emails: {
//             email: Like(message.email),
//           },
//           phoneNumbers: {
//             tel: Like(message.phoneNumber),
//           },
//         },
//       },
//     },
//   });

//   const response = await request.result();

//   return {
//     profile: Profile.$inflate(response.profile),
//     lead: Lead.$inflate(response.lead),
//     kintin: Kintin.$inflate(response.kintin),
//     accountReferral: AccountReferral.$inflate(
//       response.accountReferral,
//       message.sort,
//     ),
//     accountReferralCode: AccountReferralCode.$inflate(
//       response.accountReferralCode,
//     ),
//     introducerContact: IntroducerContact.$inflate(response.introducerContact),
//     pagination: getPagination(response),
//   };
// };

import { AuthService } from "@/service/auth.service";
import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Like } from "@kinherit/orm/index";
import { AccountReferral } from "@kinherit/sdk";

export const ReadAccountReferralHandler = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/account-referral",
  method: "get",
  rootModel: AccountReferral,
  parse: (message: {
    name: string;
    email: string;
    phoneNumber: string;
    partnerPortalGroup: string;
  }) => {
    const company = AuthService.activeIntroducerCompany;

    if (!company) {
      throw new Error("No active company");
    }

    return {
      rawQuery: {
        partnerPortalGroup: message.partnerPortalGroup,
        introducerCompany: company.id,
      },
      query: {
        kintin: {
          // ref: Like(message.ref),
          friendlyName: Like(message.name),
          primaryPerson: {
            profile: {
              emails: {
                email: Like(message.email),
              },
              phoneNumbers: {
                tel: Like(message.phoneNumber),
              },
            },
          },
        },
      },
    };
  },
});
