import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthRequestMfParams, AuthRequestMfRoute } from "@/module/auth/page";
import { PartnerPortalIndexRoute } from "@/module/dashboard/page";

import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import {
  OpenAutocompleteDialog,
  OpenDangerDialog,
} from "@kinherit/framework/global/dialog";
import { Api, Role, User } from "@kinherit/sdk";

export const DevConfig = async () => {
  if (window.Kernel.Mode !== KernelModes.Dev) {
    return;
  }

  const option = await OpenContextMenu({
    items: [
      {
        label: "Send notifications",
        message: "Send queued notifications",
        action: () =>
          Api.resource("external", "/v2/external/cron-job/notifications")
            .method("put")
            .result(),
      },
      {
        label: "Send email logs",
        message: "Send queued email logs",
        action: () =>
          Api.resource("external", "/v2/external/cron-job/email-log")
            .method("put")
            .result(),
      },
      {
        label: "Generate Introducer Contact",
        action: async () => {
          const { username, password } = await Api.resource(
            "development",
            "/v2/development/fixtures/partner-portal/introducer-contact",
          )
            .method("post")
            .result();

          // const { session } = await window.Kernel.ActionBus.execute(
          //   "auth/session/request",
          //   {
          //     username,
          //     password,
          //   },
          // );
          const { session } =
            await window.Kernel.ActionBus.auth.session.request({
              username,
              password,
            });

          if (session.mfaRequired) {
            window.Kernel.visitRoute({
              name: AuthRequestMfRoute,
              params: {
                username: session.username,
              } satisfies AuthRequestMfParams,
            });
          } else {
            window.Kernel.visitRoute({
              name: PartnerPortalIndexRoute,
            });
          }
        },
      },
      {
        label: "Roles",
        message: "Change the roles assigned to the current session",
        action: ChangeRoles,
      },
      {
        label: "Expire session",
        message: "Set the current session to expire",
        action: async () => {
          const activeSession = ActiveSession.$getInstance();

          if (!activeSession) {
            await OpenDangerDialog({
              dialog: {
                title: "No active session",
                message: "There is no active session to expire",
              },
            });

            return;
          }

          await window.Kernel.Api.resource(
            "development",
            "/v2/development/auth/token/invalidate",
            {
              jwtToken: activeSession.$data.token,
            },
          )
            .method("put")
            .result();
        },
      },
    ],
    titleField: "label",
    trackingField: "label",
    line1Field: "message",
  });

  option.action();
};

const ChangeRoles = async () => {
  const roles = (await OpenAutocompleteDialog({
    dialog: {
      title: "Roles",
      message: "Select the roles to available to the current session",
    },
    list: {
      options: () => Role.$all(),
      mapOptions: {
        value: "id",
        label: "role",
        line1: "permissions",
      },
      isMultiSelect: true,
    },
    value: [],
  })) as Array<Role>;

  const activeSession = ActiveSession.$getInstance();

  if (!activeSession) {
    throw new Error("No active session");
  }

  const user = User.$findOneOrThrow(activeSession.$data.user);

  const currentRoles = user.roles.pluck("role").unique();

  for (const role of roles) {
    if (!currentRoles.includes(role.role)) {
      user.addRoles(role);
    }
  }
  user.$persist();
  window.Kernel.ComponentOptions.App.callRefresh();
};
