// import { Api } from "@/service/api.service";
// import { Address, QueryMask } from "@kinherit/sdk";

// export interface ReadKintinAddressMessage {
//   query?: QueryMask<typeof Address>;
//   kintin: string;
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadKintinAddressResponse {
//   addresses: Array<Address>;
// }

// export const ReadKintinAddressHandler = async (
//   message: ReadKintinAddressMessage,
// ): Promise<ReadKintinAddressResponse> => {
//   const request = Api.resource(
//     "core",
//     "/v2/core/select/address/kintin/{kintin}",
//     {
//       kintin: message.kintin,
//     },
//   )
//     .method("get")

//     .paginate(message.pagination ?? false);
//   request.buildQuery(Address).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     addresses: Address.$inflate(response.address, undefined, response.$rootIds),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Address } from "@kinherit/sdk";

export const ReadKintinAddressHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/address/kintin/{kintin}",
  method: "get",
  rootModel: Address,
  parse: (message: { kintin: string }) => ({
    params: {
      kintin: message.kintin,
    },
  }),
});
