<template>
  <PageLayout :title="computedClientName" v-if="loaded">
    <p v-if="isStandaloneKinvault">
      This account was added as the result of a client accepting a Kinvault
      invitation.
    </p>
    <template v-if="!isStandaloneKinvault">
      <Wizard
        :config="wizardConfig"
        :size="'is-small'"
        ref="wizard"
        color="is-success"
        v-model:step="wizardStep"
      />
      <p v-if="accountReferral && accountReferral.kintin">
        Assigned Estate Planner:
        {{
          accountReferral.kintin?.estatePlanners?.first()?.profile?.fullName ??
          "Unassigned"
        }}
      </p></template
    >
    <div class="columns">
      <DataListWidget
        v-if="accountReferral"
        :details="clientDetails"
        class="column mt-3"
        is-plain
        no-card
      />
      <DataListWidget
        v-if="accountReferral"
        :details="referralDetails"
        class="column"
        is-plain
        no-card
      />
      <DataListWidget
        v-if="accountReferral"
        :details="accountDetails"
        class="column"
        is-plain
        no-card
      />
    </div>
    <Message v-if="accountStatus?.text === 'On Hold'" title="On Hold">
      <ul class="list">
        <li class="list-item">
          <strong
            >On Hold till {{ accountReferral.nextActionAt?.formatLong }}</strong
          >
          <span class="is-block"
            >This client is still interested in proceeding but has requested we
            followup at a later date</span
          >
        </li>
      </ul>
    </Message>

    <Message
      v-for="(order, i) in orders"
      :key="`order-${i}`"
      :title="`${order.createdAt.formatDate}`"
    >
      <ul class="list">
        <li
          class="list-item"
          v-for="(orderItem, j) in order.orderItems"
          :key="`order-${i}-item-${j}`"
        >
          <strong>{{ orderItem.text }}</strong>
        </li>
      </ul>
    </Message>

    <Message v-if="warnings.length" title="How you can help us">
      <ul class="list">
        <li v-for="(warning, i) in warnings" :key="i" class="list-item">
          <strong>{{ warning.title }}</strong>
          <span class="is-block">{{ warning.message }}</span>
          <strong v-if="warning.cta" class="is-block">{{ warning.cta }}</strong>
        </li>
      </ul>
    </Message>
  </PageLayout>
</template>

<script lang="ts">
import { ParterPortalMixin } from "@/module/dashboard/mixin/partner-portal.mixin";
import { AuthService } from "@/service/auth.service";
import DataListWidget from "@/shared/component/DataListWidget.vue";
import PageLayout from "@/shared/component/PageLayout.vue";
import { DataListWidgetDetail } from "@/shared/component/data-list-widget";
import Message from "@kinherit/framework/component.display/message";
import { Wizard } from "@kinherit/framework/component.display/wizard";
import { WizardConfig } from "@kinherit/framework/component.display/wizard/types";
import { AccountReferral, Order } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  PartnerPortalAccountReferralDetailsParams,
  PartnerPortalAccountReferralDetailsRoute,
} from ".";

type WarningObject = {
  title: string;
  message: string;
  cta?: string;
};

export default defineComponent({
  name: PartnerPortalAccountReferralDetailsRoute,
  components: {
    DataListWidget,
    Message,
    Wizard,
    PageLayout,
  },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
    ParterPortalMixin,
  ],
  data: () => ({
    loaded: false,
  }),
  computed: {
    $params(): PartnerPortalAccountReferralDetailsParams {
      return this.$route.params as PartnerPortalAccountReferralDetailsParams;
    },
    accountReferral(): AccountReferral {
      return AccountReferral.$findOneOrThrow(this.$params.accountReferral);
    },
    orders(): Array<Order> {
      return this.accountReferral.kintin?.orders ?? [];
    },
    computedClientName(): string {
      return (
        this.accountReferral.kintin?.friendlyName ??
        this.accountReferral.lead?.profile?.fullName ??
        "Error"
      );
    },
    accountStatus(): { text: string } {
      return this.calculateAccountReferralStatus(this.accountReferral);
    },
    isStandaloneKinvault(): boolean {
      return this.accountReferral.kintin?.process?.value === "lite";
    },
    wizardConfig(): WizardConfig {
      return [
        {
          title: "Prospect", // a or lead
        },
        {
          title: "Consultation", // b-c d
          message: "& Recommendations",
        },
        {
          title: "Paid", // e/e2/f
          message: "Completing Documents",
        },
        {
          title: "Complete", // g1/2/3/h/z1/z2
          message: this.wizardCompleteStepMessage,
        },
      ];
    },
    wizardCompleteStepMessage() {
      return this.accountReferral.kintin?.stage?.value &&
        ["g3"].includes(this.accountReferral.kintin?.stage?.value)
        ? "Done pending OPG / LR"
        : "All done";
    },
    wizardStep(): number {
      if (this.accountReferral.kintin?.stage?.value) {
        if (
          ["b", "c", "d"].includes(this.accountReferral.kintin?.stage?.value)
        ) {
          return 1;
        }
        if (
          ["e", "e2", "f"].includes(this.accountReferral.kintin?.stage?.value)
        ) {
          return 2;
        }
        if (
          ["g1", "g2", "g3", "h", "z1", "z2"].includes(
            this.accountReferral.kintin?.stage?.value,
          )
        ) {
          return 3;
        }
      }
      return 0;
    },
    warnings(): Array<WarningObject> {
      const warnings: Array<WarningObject> = [];

      if (
        null === this.accountReferral.kintin &&
        this.accountReferral.lead?.profile?.phoneNumbers?.length === 0
      ) {
        warnings.push({
          title: "Missing Phone Number",
          message: "We are missing a phone number for this referral.",
          cta: "Please email legalassistants@kinherit.co.uk with a phone number for this referral.",
        });
      }

      if (
        null !== this.accountReferral.kintin &&
        !this.accountReferral.kintin?.isActive
      ) {
        warnings.push({
          title: "Account Inactive",
          message:
            "This account has been flagged as inactive. This is usually due to inactivity on behalf of the client, and where we have been unable to contact them.",
          cta: "Please contact the client to confirm their intentions.",
        });
      }

      if (
        null === this.accountReferral.kintin &&
        ["uc_no_tel", "uc_with_tel"].includes(
          this.accountReferral.lead?.status?.value ?? "",
        )
      ) {
        warnings.push({
          title: "Uncontactable",
          message:
            "We have tried to contact this client mulitple times, but have been unable to reach them.",
          cta: "Please contact the client to confirm their intentions.",
        });
      }

      return warnings;
    },
    clientDetails(): Array<DataListWidgetDetail> {
      if (!this.accountReferral) {
        return [];
      }
      return [
        {
          label: "Name",
          value:
            this.accountReferral.kintin?.friendlyName ??
            this.accountReferral.lead?.profile?.fullName ??
            "Error",
        },
        {
          label: "Email",
          value:
            this.accountReferral.lead?.profile?.emails &&
            this.accountReferral.lead?.profile?.emails?.length > 0
              ? this.accountReferral.lead?.profile?.emails
                  .map((e) => e.email)
                  .join(", ")
              : "None Supplied",
        },
        {
          label: "Phone",
          value:
            this.accountReferral.lead?.profile?.phoneNumbers &&
            this.accountReferral.lead?.profile?.phoneNumbers?.length > 0
              ? this.accountReferral.lead?.profile?.phoneNumbers
                  .map((t) => t.tel)
                  .join(", ")
              : "None Supplied",
        },
      ];
    },
    referralDetails(): Array<DataListWidgetDetail> {
      if (!this.accountReferral) {
        return [];
      }
      return [
        {
          label: "Referred On",
          value: this.accountReferral.createdAt.formatDate,
        },
        {
          label: "Referred By",
          value:
            this.accountReferral.contact?.profile?.fullName ?? "Unassigned",
        },
        {
          label: "Referral Code",
          value: this.accountReferral.referralCode?.code ?? "None",
        },
      ];
    },
    accountDetails(): Array<DataListWidgetDetail> {
      if (!this.accountReferral) {
        return [];
      }
      return [
        {
          label: "Status",
          value: this.accountStatus.text,
        },
        {
          label: "Account Type",
          value:
            this.accountReferral.kintin?.type?.value === "joint"
              ? "Joint"
              : this.accountReferral.kintin?.type?.value === "single"
                ? "Individual"
                : "Prospect",
        },
        {
          label: "Account Number",
          value: this.accountReferral.kintin?.ref ?? "N/A",
        },
      ];
    },
  },
  async beforeMount(): Promise<void> {
    // await window.Kernel.ActionBus.execute("partner-portal/clients/record", {
    //   accountReferral: this.$params.accountReferral,
    // });
    await window.Kernel.ActionBus.clients.record({
      message: {
        accountReferral: this.$params.accountReferral,
      },
    });

    const permissions = this.$auth.portalPermissions;

    if (permissions.viewOrderProducts) {
      // await window.Kernel.ActionBus.execute(
      //   "partner-portal/clients/order/read",
      //   {
      //     accountReferral: this.$params.accountReferral,
      //     sort: {
      //       by: "createdAt",
      //       direction: "desc",
      //     },
      //   },
      // );
      await window.Kernel.ActionBus.clients.order.read({
        message: {
          accountReferral: this.$params.accountReferral,
        },
        sort: {
          by: "createdAt",
          direction: "desc",
        },
      });
    }

    this.loaded = true;
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "AccountReferralDetails",
  "imports": {
    "WizardWrapper": "@kinherit/framework/component.display/wizard/wizard.test"
  },
  "methods": {
    "back": {
      "type": "click",
      "selector": ".back-button"
    },
    "wizardStep": {
      "type": "to-one",
      "selector": ".wizard",
      "wrapper": "WizardWrapper"
    }
  }
}
</cypress-wrapper>
