import "@kinherit/framework/api/handlers";
import Style from "@kinherit/framework/style";
import "@kinherit/ts-common/prototype/array";
import "@kinherit/ts-common/prototype/console";
import "@kinherit/ts-common/prototype/number";
import "@kinherit/ts-common/prototype/object";
import "@kinherit/ts-common/prototype/string";

import "@/config/style.scss";

import "@/module";

import { AppConfig } from "@/config/app.config";
import { FooterConfig } from "@/config/footer.config";
import "@/config/module.config";
import { NavbarConfig } from "@/config/navbar.config";
import App from "@kinherit/framework/core/internal-components/app";
import { Kernel, KernelInterface } from "@kinherit/framework/core/kernel";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";

import { createApp } from "vue";

import { ActionBus } from "@/module/action-bus";
import { ActiveSession } from "@/module/auth/model/active-session.model";
import { Router } from "./config/router.config";

const kernel = new Kernel({
  APP_VERSION: process.env.APP_VERSION as string,
  Mode:
    (import.meta.env.VITE_APP_KERNEL_MODE as KernelModes) ?? KernelModes.Dev,
  Components: {
    footer: FooterConfig,
    navbar: NavbarConfig,
    app: AppConfig,
  },
  Router,
  FrameworkUrl: import.meta.env.VITE_APP_API_URL as string,
  StripeKey: import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY as string,
  SentryDsn:
    "https://1600c6124efc92cd37dddf36c92e92aa@o4506846247321600.ingest.us.sentry.io/4507242549018624",
  SentryBeforeSend(event, hint, sentry) {
    const username = ActiveSession.$getInstance()?.username;
    sentry.setUser(username ? { username: username } : null);
    event.environment = import.meta.env.MODE;

    return event;
  },
  SentryRecord: true,
  ActionBus: ActionBus,
});

(async () => {
  const app = createApp(App);

  await kernel.init(app, async () => {
    kernel.injectStylesheet("framework", Style);

    kernel.Theme.setCssVars([
      {
        key: "--page-padding",
        value: "0",
      },
      {
        key: "--site-header-logo-width",
        value: "110px",
      },
      {
        key: "--footer-background",
        value: "#222",
      },
      {
        key: "--footer-color",
        value: "#fff",
      },
      {
        key: "--font-family-heading",
        value: "DM Serif Text",
      },
    ]);

    const activeSession = ActiveSession.$getInstance();

    if (false === activeSession?.mfaRequired) {
      try {
        // await window.Kernel.ActionBus.execute("auth/session/complete", {
        //   activeSession,
        // });
        await window.Kernel.ActionBus.auth.session.complete();
      } catch (e) {
        console.error(e);
      }
    }

    window.Kernel.ComponentOptions.Navbar.callRefresh();
  });
})();

declare global {
  interface Window {
    Kernel: KernelInterface<typeof ActionBus>;
  }
}
