import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { In, Like } from "@kinherit/orm";
import { KintinAccess } from "@kinherit/sdk";

// export const ReadKintinAccessHandler = async (message: {
//   name: string;
//   ref: string;
//   email: string;
//   phoneNumber: string;
//   status: string[];
//   sort: {
//     by: keyof IKintinAccess;
//     direction: "desc" | "asc";
//   };
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }) => {
//   const request = Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/kintin-access",
//   )
//     .method("get")
//     .sort(message.sort)
//     .paginate(message.pagination);

//   request.buildQuery(KintinAccess).where({
//     kintin: {
//       ref: Like(message.ref),
//       friendlyName: Like(message.name),
//       primaryPerson: {
//         profile: {
//           emails: {
//             email: Like(message.email),
//           },
//           phoneNumbers: {
//             tel: Like(message.phoneNumber),
//           },
//         },
//       },
//       status: {
//         id: In(message.status),
//       },
//     },
//   });

//   const response = await request.result();

//   return {
//     kintinAccess: KintinAccess.$inflate(response.kintinAccess),
//     kintin: Kintin.$inflate(response.kintin),
//     introducerContact: IntroducerContact.$inflate(response.introducerContact),
//     accountReferral: AccountReferral.$inflate(response.accountReferral),
//     accountReferralCode: AccountReferralCode.$inflate(
//       response.accountReferralCode,
//     ),
//     profile: Profile.$inflate(response.profile),
//     pagination: getPagination(response),
//   };
// };

export const ReadKintinAccessHandler = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/kintin-access",
  method: "get",
  rootModel: KintinAccess,
  parse: (message: {
    name: string;
    ref: string;
    email: string;
    phoneNumber: string;
    status: string[];
  }) => ({
    query: {
      kintin: {
        ref: Like(message.ref),
        friendlyName: Like(message.name),
        primaryPerson: {
          profile: {
            emails: {
              email: Like(message.email),
            },
            phoneNumbers: {
              tel: Like(message.phoneNumber),
            },
          },
        },
        status: {
          id: In(message.status),
        },
      },
    },
  }),
});
