import {
  AccountReferral,
  AccountReferralCode,
  Address,
  Adviser,
  Attorney,
  Beneficiary,
  BrandedKinvault,
  BusinessAsset,
  CashDebt,
  CommunicationNote,
  CpdSession,
  Credential,
  CreditNote,
  EmailAddress,
  EmailLog,
  EmailTemplate,
  Exclusion,
  Executor,
  FileLog,
  Gift,
  Guardian,
  IAccountReferral,
  IAccountReferralCode,
  IAddress,
  IAdviser,
  IAttorney,
  IBeneficiary,
  IBrandedKinvault,
  IBusinessAsset,
  ICashDebt,
  ICommunicationNote,
  ICpdSession,
  ICredential,
  ICreditNote,
  IEmailAddress,
  IEmailLog,
  IEmailTemplate,
  IExclusion,
  IExecutor,
  IFileLog,
  IGift,
  IGuardian,
  IIntroducerBillingContact,
  IIntroducerCompany,
  IIntroducerContact,
  IIntroducerContract,
  IIntroducerNote,
  IInvestment,
  IKintin,
  IKintinAccess,
  IKnowledgeBaseCategory,
  IKnowledgeBaseDocument,
  ILead,
  ILifeTimeGift,
  IMfAuthMethod,
  INote,
  INotification,
  IOrder,
  IOtherAsset,
  IPension,
  IPerson,
  IPhoneNumber,
  IPhysicalStorageFile,
  IPhysicalStorageFileLog,
  IPolicy,
  IProfile,
  IProperty,
  ISignDoc,
  ISubscription,
  ITag,
  ITrustee,
  IUploadForm,
  IUser,
  IWebsite,
  IntroducerBillingContact,
  IntroducerCompany,
  IntroducerContact,
  IntroducerContract,
  IntroducerNote,
  Investment,
  Kintin,
  KintinAccess,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
  Lead,
  LifeTimeGift,
  MfAuthMethod,
  Note,
  Notification,
  Option,
  Order,
  OtherAsset,
  Pension,
  Person,
  PhoneNumber,
  PhysicalStorageFile,
  PhysicalStorageFileLog,
  Policy,
  Profile,
  Property,
  SignDoc,
  Subscription,
  Tag,
  Trustee,
  UploadForm,
  User,
  Website,
} from "@kinherit/sdk";
import { Currency, DateTime } from "@kinherit/ts-common";
import { Uuid } from "@kinherit/ts-common/service/uuid";

export const CreateProfile = (data: Partial<IProfile> = {}) =>
  Profile.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        firstName: "",
        middleNames: "",
        lastName: "",
        organisationName: "",
        organisationNumber: "",
        addresses: [],
        emails: [],
        phoneNumbers: [],
        websites: [],
        createdAt: new DateTime().formatMachine,
        emailCampaigns: [],
        jobTitle: null,
        knownAs: null,
        newsletterCampaigns: null,
        appointments: [],
      },
      data,
    ),
  );

export const CreatePerson = (data: Partial<IPerson> = {}) =>
  Person.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        type: "person",
        kintin: null,
        profile: CreateProfile().$persist().id,
        communicationNotes: [],
        trusteeFor: [],
        witnessFor: [],
        notifyOnDeath: false,
        executorFor: [],
        guardianFor: [],
        under18ForPlanningPurposes: false,
        isTrusted: false,
        receivingGifts: [],
        emailLogs: [],
        beneficiaryFor: [],
        attorneyFor: [],
        adviserFor: [],
        isNotifiable: false,
        appointedTrustee: [],
        appointedWitness: [],
        appointedExecutor: [],
        appointedGuardian: [],
        appointedBeneficiary: [],
        appointedAttorney: [],
        appointedAdviser: [],
        notes: [],
        relations: [],
        relationOf: [],
        sendingGifts: [],
        creditNotes: [],
        exclusions: [],
        relationToPrimaryPerson: null,
        relationToSecondaryPerson: null,
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateGift = (data: Partial<IGift> = {}) =>
  Gift.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        address: null,
        forPerson: null,
        sentBy: null,
        type: null,
        classGiftType: null,
        kintin: null,
        amount: null,
        notes: null,
        onSecondDeath: null,
        asPrimaryResidence: null,
        charityName: null,
        charityNo: null,
        ifPredeceased: null,
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateExclusion = (data: Partial<IExclusion> = {}) =>
  Exclusion.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        ownedBy: null,
        kintin: null,
        who: null,
        why: null,
        relationship: null,
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateTag = (data: Partial<ITag> = {}) =>
  Tag.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        name: "",
        description: "",
        kintinCount: [],
        appointmentCount: [],
        introducerCompanyCount: [],
        introducerContactCount: [],
        leadCount: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateIntroducerNote = (data: Partial<IIntroducerNote> = {}) =>
  IntroducerNote.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        contact: null,
        company: null,
        note: CreateNote().id,
        dueAtNotifySales: false,
        dueAtNotifySpecialist: false,
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateNote = (data: Partial<INote> = {}) =>
  Note.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        name: "",
        type: "note",
        completedAt: null,
        dueAt: null,
        notes: "",
        pinned: false,
        notifications: [],
        isPublic: false,
      },
      data,
    ),
  );

export const CreateIntroducerContract = (
  data: Partial<IIntroducerContract> = {},
) =>
  IntroducerContract.$create(
    Object.assign(
      {
        type: null,
        vat: null,
        parentContract: null,
        company: null,
        sentAt: null,
        datedAt: null,
        isSigned: false,
        ai_5a_percent: null,
        ai_5b_percent: null,
        ai_5c_percent: null,
        i_4c_percent: null,
        ai_5a_months: null,
        ai_5b_months: null,
        ai_5c_months: null,
        i_4c_months: null,
        prePaidClients: null,
        notes: null,
        childContracts: [],
        datedTill: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateReferral = (data: Partial<IAccountReferral> = {}) =>
  AccountReferral.$create(
    Object.assign(
      {
        company: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        contact: null,
        referralCode: null,
      },
      data,
    ),
  );

export const CreateKnowledgeBaseCategory = (
  data: Partial<IKnowledgeBaseCategory> = {},
) =>
  KnowledgeBaseCategory.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        name: "",
        slug: "",
        documents: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateKnowledgeBaseDocument = (
  data: Partial<IKnowledgeBaseDocument> = {},
) =>
  KnowledgeBaseDocument.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        title: "",
        content: "",
        categories: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateMfAuthMethod = (data: Partial<IMfAuthMethod> = {}) =>
  MfAuthMethod.$create(
    Object.assign(
      {
        type: null,
        status: null,
        user: null,
        alias: null,
        key: null,
        device: null,
        setupCompleteAt: null,
        id: Uuid.generate(),
      },
      data,
    ),
  );

export const CreateOrder = (data: Partial<IOrder> = {}) =>
  Order.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        kintin: null,
        trustReg: null,
        status: null,
        paymentType: null,
        orderItems: [],
        listPrice: new Currency({ amount: 0, type: "GBP" }),
        listPriceExVat: new Currency({ amount: 0, type: "GBP" }),
        listPriceVat: new Currency({ amount: 0, type: "GBP" }),
        extrasPrice: new Currency({ amount: 0, type: "GBP" }),
        extrasPriceExVat: new Currency({ amount: 0, type: "GBP" }),
        extrasPriceVat: new Currency({ amount: 0, type: "GBP" }),
        discountValue: new Currency({ amount: 0, type: "GBP" }),
        totalPrice: new Currency({ amount: 0, type: "GBP" }),
        totalPriceExVat: new Currency({ amount: 0, type: "GBP" }),
        totalPriceVat: new Currency({ amount: 0, type: "GBP" }),
        opgFee: new Currency({ amount: 0, type: "GBP" }),
        totalPriceIncFees: new Currency({ amount: 0, type: "GBP" }),
        totalPriceExVatIncFees: new Currency({ amount: 0, type: "GBP" }),
        totalPriceVatIncFees: new Currency({ amount: 0, type: "GBP" }),
        discountPercentage: 0,
        proExecutor: null,
        proTrustee: null,
        coolingOff: null,
        paidAt: null,
        invoiceNumber: null,
        notes: null,
        paymentSignKey: null,
        transactionId: null,
        overrideListPrice: new Currency({ amount: 0, type: "GBP" }),
        feesPayOutIntro: new Currency({ amount: 0, type: "GBP" }),
        feesPayoutAgent: new Currency({ amount: 0, type: "GBP" }),
        feesPayOutIntroDate: null,
        feesPayOutAgentDate: null,
        feesPayOutParentAgent: new Currency({ amount: 0, type: "GBP" }),
        feesPayOutParentAgentDate: null,
        feesConfirmed: false,
        discountReason: null,
        xeroIntroducerBillId: null,
        xeroAgentBillId: null,
        billingName: null,
        billingAddress1: null,
        billingAddress2: null,
        billingCity: null,
        billingRegion: null,
        billingPostalCode: null,
        stripeSessions: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateCpdSession = (data: Partial<ICpdSession> = {}) =>
  CpdSession.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        address: null,
        type: null,
        introducers: [],
        date: null,
        registrations: [],
        title: null,
        duration: null,
        outcomes: null,
        allowBookings: false,
        websites: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateReferralCode = (data: Partial<IAccountReferralCode> = {}) =>
  AccountReferralCode.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        category: null,
        isForUsers: false,
        brandedKinvault: null,
        code: "",
        notes: null,
        name: null,
        referrals: [],
      },
      data,
    ),
  );

export const CreateSubscription = (data: Partial<ISubscription> = {}) =>
  Subscription.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        status: null,
        kintin: null,
        files: [],
        productSku: null,
        provider: null,
        billingDate: null,
        procXero: false,
        procDd: false,
        notes: null,
        subscriptionValue: null,
      },
      data,
    ),
  );

export const CreateBrandedKinvault = (data: Partial<IBrandedKinvault> = {}) =>
  BrandedKinvault.$create(
    Object.assign(
      {
        brandedKinvaultMemberships: [],
        teams: [],
        referrals: [],
        profile: CreateProfile().id,
        kinvaultTheme: null,
        frontendUrl: null,
        type: null,
        notes: null,
        companyUrl: null,
        clientPoolType: null,
        systemEmail: null,
        emailLogo: null,
        siteMode: null,
        restrictUserEmailDomain: null,
        allowedEmailDomains: [],
        redirectUrl: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateFile = (data: Partial<IFileLog> = {}) =>
  FileLog.$create(
    Object.assign(
      {
        kintin: null,
        trustReg: null,
        type: null,
        subType: null,
        tab: null,
        fileName: null,
        s3Location: null,
        fileExtension: null,
        fileSize: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateSignDoc = (data: Partial<ISignDoc> = {}) =>
  SignDoc.$create(
    Object.assign(
      {
        person: null,
        data: null,
        kintin: null,
        uploads: [],
        type: null,
        signKey: null,
        ipAddress: null,
        userAgent: null,
        signedAt: null,
        content: null,
        stripeSessions: [],
        clauses: [],
        conditions: [],
        products: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateUploadForm = (data: Partial<IUploadForm> = {}) =>
  UploadForm.$create(
    Object.assign(
      {
        kintin: null,
        submissions: [],
        signKey: null,
        name: null,
        validTill: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateGuardian = (data: Partial<IGuardian> = {}) =>
  Guardian.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateBeneficiary = (data: Partial<IBeneficiary> = {}) =>
  Beneficiary.$create(
    Object.assign(
      {
        person: null,
        ifPredeceased: null,
        classGiftType: null,
        reserveclassGiftType: null,
        reservePerson: null,
        forPerson: null,
        position: null,
        sharePercentage: null,
        shareFraction: null,
        notes: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateExecutor = (data: Partial<IExecutor> = {}) =>
  Executor.$create(
    Object.assign(
      {
        forPerson: null,
        person: null,
        isReserve: null,
        notes: null,
        professionalSupport: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateTrustee = (data: Partial<ITrustee> = {}) =>
  Trustee.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        isReserve: null,
        notes: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateAttorney = (data: Partial<IAttorney> = {}) =>
  Attorney.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        isReserve: null,
        notes: null,
        isCertProv: null,
        isHealthWealth: null,
        isPropFinance: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateAdviser = (data: Partial<IAdviser> = {}) =>
  Adviser.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        defaultTrustIfa: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateProperty = (data: Partial<IProperty> = {}) =>
  Property.$create(
    Object.assign(
      {
        address: null,
        howOwned: null,
        ownership: null,
        type: null,
        kintin: null,
        titleDeed: null,
        price: new Currency({}),
        value: new Currency({}),
        lessMortgage: new Currency({}),
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateKintinAccess = (data: Partial<IKintinAccess> = {}) =>
  KintinAccess.$create(
    Object.assign(
      {
        accessType: "readonly",
        kintin: null,
        user: null,
        allowAccess: true,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        legal: "none",
        assets: "none",
        contacts: "none",
        admin: "none",
        care: "none",
        death: "none",
        misc: "none",
        settings: "none",
      },
      data,
    ),
  );

export const CreateCreditNote = (data: Partial<ICreditNote> = {}) =>
  CreditNote.$create(
    Object.assign(
      {
        relatedOrder: null,
        relatedKintin: null,
        relatedTrustReg: null,
        issuedBy: null,
        relatedUser: null,
        relatedPerson: null,
        note: CreateNote().id,
        value: null,
        issuedAt: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );
export const CreateNotification = (data: Partial<INotification> = {}) =>
  Notification.$create(
    Object.assign(
      {
        user: null,
        note: null,
        status: null,
        statusChangedAt: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateEmailTemplate = (data: Partial<IEmailTemplate> = {}) =>
  EmailTemplate.$create(
    Object.assign(
      {
        name: null,
        subject: null,
        type: null,
        includeTo: null,
        preloadedFiles: [],
        requiredAttachments: [],
        emails: [],
        body: null,
        allowAttachments: null,
        strictAttachments: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreatePhysicalStorageFile = (
  data: Partial<IPhysicalStorageFile> = {},
) =>
  PhysicalStorageFile.$create(
    Object.assign(
      {
        file: null,
        subscription: null,
        logs: [],
        title: null,
        length: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreatePhysicalStorageFileLog = (
  data: Partial<IPhysicalStorageFileLog> = {},
) =>
  PhysicalStorageFileLog.$create(
    Object.assign(
      {
        logAction: null,
        file: null,
        createdBy: null,
        confirmedBy: null,
        completedAt: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateLead = (data: Partial<ILead> = {}) =>
  Lead.$create(
    Object.assign(
      {
        referral: null,
        assignedTo: null,
        ownedBy: null,
        referringKintin: null,
        status: null,
        tags: [],
        profile: CreateProfile().$persist().id,
        description: null,
        CommunicationNotes: [],
        notes: [],
        externalLeadId: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        noteCount: 0,
      },
      data,
    ),
  );

export const CreateUser = (data: Partial<IUser> = {}) =>
  User.$create(
    Object.assign(
      {
        notifications: null,
        profile: CreateProfile().$persist().id,
        credential: CreateCredential().$persist().id,
        brandedKinvaultMemberships: [],
        roles: [],
        acuityUserId: null,
        displayUserInPortal: null,
        lastLoggedIn: null,
        lastPasswordReset: null,
        issuedCreditNotes: [],
        receivedCreditNotes: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateIntroducerContact = (
  data: Partial<IIntroducerContact> = {},
) =>
  IntroducerContact.$create(
    Object.assign(
      {
        assistantProfile: null,
        status: null,
        callStatus: null,
        remittanceType: null,
        profile: CreateProfile().$persist().id,
        tags: [],
        company: null,
        cpds: [],
        jobTitle: null,
        knownAs: null,
        newsLetter: false,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateIntroducerBillingContact = (
  data: Partial<IIntroducerBillingContact> = {},
) =>
  IntroducerBillingContact.$create(
    Object.assign(
      {
        introducerRemittanceType: null,
        agentRemittanceType: null,
        profile: CreateProfile().$persist().id,
        company: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateKintin = (data: Partial<IKintin> = {}) =>
  Kintin.$create(
    Object.assign(
      {
        primaryPerson: CreatePerson().$persist().id,
        secondaryPerson: null,
        type: null,
        status: null,
        referral: null,
        sourceKintin: null,
        process: null,
        stage: null,
        creditNotes: [],
        willRevisions: [],
        friendlyName: null,
        ref: null,
        estatePlanners: [],
        legalAssistant: null,
        businessAssets: [],
        cashDebts: [],
        gifts: [],
        investments: [],
        lifeTimeGifts: [],
        otherAssets: [],
        pensions: [],
        properties: [],
        tags: [],
        orders: [],
        assignedTeams: [],
        assignedUsers: [],
        subscriptions: [],
        willReviews: [],
        policies: [],
        exclusions: [],
        signDocs: [],
        uploadForms: [],
        files: [],
        whyEol: null,
        callScript: null,
        permissions: [],
        notificationDate: null,
        statusUpdatedAt: null,
        nextActionAt: null,
        statusChangeReason: null,
        checks: [],
        checkFollowUps: [],
        checkNotes: [],
        people: [],
        externalLeadId: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateIntroducerCompany = (
  data: Partial<IIntroducerCompany> = {},
) =>
  IntroducerCompany.$create(
    Object.assign(
      {
        assignedTo: null,
        type: null,
        stage: null,
        status: null,
        seniorAdviser: null,
        introducedBy: null,
        kintinCount: 0,
        profile: CreateProfile().$persist().id,
        tags: [],
        contacts: [],
        billingContacts: [],
        contracts: [],
        referralCodes: [],
        referrals: [],
        network: null,
        kinvaultStatus: null,
        description: null,
        lastConversation: null,
        trustRegXeroContactId: null,
        notes: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateCredential = (data: Partial<ICredential> = {}) =>
  Credential.$create(
    Object.assign(
      {
        password: null,
        username: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateEmailLog = (data: Partial<IEmailLog> = {}) =>
  EmailLog.$create(
    Object.assign(
      {
        status: null,
        data: null,
        preloadedFiles: [],
        attachments: [],
        template: null,
        cc: [],
        bcc: [],
        to: [],
        from: null,
        body: "",
        title: null,
        subject: null,
        sentAt: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateCommunicationNote = (
  data: Partial<ICommunicationNote> = {},
) =>
  CommunicationNote.$create(
    Object.assign(
      {
        communicationType: null,
        note: null,
        reference: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateBusinessAsset = (data: Partial<IBusinessAsset> = {}) =>
  BusinessAsset.$create(
    Object.assign(
      {
        address: null,
        numberOfShares: null,
        type: null,
        businessType: null,
        howOwned: null,
        ownedBy: null,
        kintin: null,
        companyName: null,
        value: new Currency({}),
        loanValue: new Currency({}),
        ownedSince: null,
        notes: null,
        location: Option.$findOneByOrThrow({
          group: "locationTypes",
          value: "within_uk",
        }).id,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        updatedAt: null,
        deletedAt: null,
      },
      data,
    ),
  );

export const CreateCashDebt = (data: Partial<ICashDebt> = {}) =>
  CashDebt.$create(
    Object.assign(
      {
        kintin: null,
        type: null,
        ownedBy: null,
        provider: null,
        value: new Currency({}),
        location: Option.$findOneByOrThrow({
          group: "locationTypes",
          value: "within_uk",
        }).id,
        notes: null,
        accountNumber: null,
        sortCode: null,
        iban: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        updatedAt: null,
        deletedAt: null,
      },
      data,
    ),
  );

export const CreateInvestment = (data: Partial<IInvestment> = {}) =>
  Investment.$create(
    Object.assign(
      {
        adviser: null,
        type: null,
        ownedBy: null,
        kintin: null,
        provider: null,
        value: new Currency({}),
        location: Option.$findOneByOrThrow({
          group: "locationTypes",
          value: "within_uk",
        }).id,
        notes: null,
        adviserIsCurrent: null,
        accountNumber: null,
        sortCode: null,
        iban: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        updatedAt: null,
        deletedAt: null,
      },
      data,
    ),
  );

export const CreatePension = (data: Partial<IPension> = {}) =>
  Pension.$create(
    Object.assign(
      {
        adviser: null,
        type: null,
        ownedBy: null,
        kintin: null,
        provider: null,
        policyNo: null,
        adviserIsCurrent: false,
        value: new Currency({}),
        location: Option.$findOneByOrThrow({
          group: "locationTypes",
          value: "within_uk",
        }).id,
        notes: null,
        accountNumber: null,
        sortCode: null,
        iban: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        updatedAt: null,
        deletedAt: null,
      },
      data,
    ),
  );

export const CreateLifeTimeGift = (data: Partial<ILifeTimeGift> = {}) =>
  LifeTimeGift.$create(
    Object.assign(
      {
        ownedBy: null,
        type: null,
        kintin: null,
        dateGiven: null,
        recipient: null,
        value: new Currency({}),
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        updatedAt: null,
        deletedAt: null,
      },
      data,
    ),
  );

export const CreateOtherAsset = (data: Partial<IOtherAsset> = {}) =>
  OtherAsset.$create(
    Object.assign(
      {
        type: null,
        ownedBy: null,
        kintin: null,
        title: null,
        notes: null,
        value: new Currency({}),
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        updatedAt: null,
        deletedAt: null,
      },
      data,
    ),
  );

export const CreatePolicy = (data: Partial<IPolicy> = {}) =>
  Policy.$create(
    Object.assign(
      {
        adviser: null,
        type: null,
        ownership: null,
        payoutType: null,
        kintin: null,
        provider: null,
        policyNo: null,
        value: new Currency({}),
        validTill: null,
        notes: null,
        adviserIsCurrent: null,
        location: Option.$findOneByOrThrow({
          group: "locationTypes",
          value: "within_uk",
        }).id,
        valueLumpDeath: null,
        valueLumpCritical: null,
        valueMonthlyIncome: null,
        valueMonthlyDeath: null,
        valueMonthlyCritical: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        updatedAt: null,
        deletedAt: null,
      },
      data,
    ),
  );

export const CreateEmailAddress = (data: Partial<IEmailAddress> = {}) =>
  EmailAddress.$create(
    Object.assign(
      {
        email: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateAddress = (data: Partial<IAddress> = {}) =>
  Address.$create(
    Object.assign(
      {
        summary: null,
        profile: null,
        line1: null,
        line2: null,
        city: null,
        state: null,
        postcode: null,
        country: "GB",
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        primaryMailing: false,
        primaryResidential: false,
      },
      data,
    ),
  );

export const CreatePhoneNumber = (data: Partial<IPhoneNumber> = {}) =>
  PhoneNumber.$create(
    Object.assign(
      {
        tel: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateWebsite = (data: Partial<IWebsite> = {}) =>
  Website.$create(
    Object.assign(
      {
        url: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );

export const CreateFileLog = (data: Partial<IFileLog> = {}) =>
  FileLog.$create(
    Object.assign(
      {
        email: null,
        kintin: null,
        trustReg: null,
        tab: null,
        type: null,
        subType: null,
        isRichText: null,
        businessAsset: null,
        cashDebt: null,
        investment: null,
        otherAsset: null,
        pension: null,
        policy: null,
        property: null,
        fileName: null,
        fileExtension: null,
        fileSize: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  );
