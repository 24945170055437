import { Api } from "@/service/api.service";

interface SetPasswordFromTokenMessage {
  token: string;
  newPassword: string;
}

interface SetPasswordFromTokenResult {
  [key: string]: never;
}

export const SetPasswordFromTokenHandler = async (
  message: SetPasswordFromTokenMessage,
): Promise<SetPasswordFromTokenResult> => {
  console.log(message);
  await Api.resource("auth", "/v2/auth/change-password/token/{token}", {
    token: message.token,
  })
    .method("put")
    .body({
      newPassword: message.newPassword,
    })
    .result();

  return {};
};
