import { OpenSnackBar } from "@kinherit/framework/global/snack-bar";

const SnackBarErrorConfig = {
  text: "An error has occurred",
  color: "is-danger" as const,
  shade: "is-light" as const,
  actionColor: "is-danger" as const,
};

const SnackBarSuccessConfig = {
  text: "Success",
  color: "is-success" as const,
  shade: "is-light" as const,
  actionColor: "is-success" as const,
};

export class SnackBarService {
  public static get mixin() {
    return {
      computed: {
        $snackBar: () =>
          SnackBarService as {
            errors: typeof SnackBarService.errors;
            success: typeof SnackBarService.success;
          },
      },
    };
  }

  public static errors = {
    mustBeLoggedIn: () =>
      OpenSnackBar({
        ...SnackBarErrorConfig,
        text: "You must be logged in to access this page.",
      }),
    mustBeAuthenticated: () =>
      OpenSnackBar({
        ...SnackBarErrorConfig,
        text: "You must authenticate to access this page",
      }),
    notEnoughPermissions: () =>
      OpenSnackBar({
        ...SnackBarErrorConfig,
        text: "You do not have permission to access this page",
      }),
    invalidForm: () =>
      OpenSnackBar({
        ...SnackBarErrorConfig,
        text: "Please fill out all required fields",
      }),
    generic: (message: string) =>
      OpenSnackBar({
        ...SnackBarErrorConfig,
        text: message,
      }),
  };

  public static success = {
    saved: () =>
      OpenSnackBar({
        ...SnackBarSuccessConfig,
        text: "Save complete",
      }),
    generic: (message: string) =>
      OpenSnackBar({
        ...SnackBarSuccessConfig,
        text: message,
      }),
  };

  public static toast = ({
    text,
    type,
  }: {
    text: string;
    type: "success" | "error";
  }) => {
    OpenSnackBar({
      ...(type === "success" ? SnackBarSuccessConfig : SnackBarErrorConfig),
      text,
    });
  };
}
