// import { Api } from "@/service/api.service";
// import { Adviser, Person, Profile, QueryMask } from "@kinherit/sdk";

// export interface ReadAdviserMessage {
//   query?: QueryMask<typeof Adviser>;
//   pagination?:
//     | {
//         currentPage: number;
//         perPage: number;
//       }
//     | false;
// }

// interface ReadAdviserResponse {
//   advisers: Array<Adviser>;
//   profiles: Array<Profile>;
//   people: Array<Person>;
// }

// export const ReadAdviserHandler = async (
//   message: ReadAdviserMessage,
// ): Promise<ReadAdviserResponse> => {
//   const request = Api.resource("core", "/v2/core/select/adviser")
//     .method("get")

//     .paginate(message.pagination ?? false);
//   request.buildQuery(Adviser).where(message.query ?? {});

//   const response = await request.result();

//   return {
//     advisers: Adviser.$inflate(response.adviser, undefined, response.$rootIds),
//     profiles: Profile.$inflate(response.profile),
//     people: Person.$inflate(response.person),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Adviser } from "@kinherit/sdk";

export const ReadAdviserHandler = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/adviser",
  method: "get",
  rootModel: Adviser,
  parse: () => ({}),
});
